const es = {
  CreativeTasks: {
    Title: "Tareas creativas",
    UpdateTaskTeacher: "Guardar",
    SubmitTaskTeacher: "Enviar resultados",
    UpdateTaskStudent: "Guardar borrador",
    SubmitTaskStudent: "Guardar y enviar para revisión",
    AnswerHeaderStatistics:
      " Preguntas: {{questions}} | Caracteres: {{symbols}} | Palabras: {{words}} | Oraciones: {{sentenses}}",
    QuestionsAndComments: "Errores y comentarios:",
    HoverForDisplay: "Pase el ratón para mostrar:",
    CheckingHistory: "Historial de revisiones",
    CurrentVersion: "Versión actual",
    TeacherComment: "Comentario del profesor sobre el trabajo:",
    CommentPlaceholder: "Puedes dejar un comentario sobre el trabajo aquí",
    SaveCorrection: "Guardar",
    CancelCorrection: "Cancelar",
    Grammar: "Gramática",
    Lexical: "Léxico",
    Comment: "Comentario",
    ErrorRangeFilled:
      "Has utilizado todos los tipos de errores en el rango seleccionado",
    SaveError: "Guardar",
    GrammarErrorTitle: "Gramática",
    ResultTitle: "Resultados de la tarea creativa",
    Student: "Estudiante",
    Teacher: "Profesor",
    CurrentVersionTime: "Versión actual: {{time}}",
    TaskText: "Tarea",
    HasErrors: "Hay errores",
    WithoutErrors: "Completado sin errores",
    UpdateSavedTeacher: "Tus cambios han sido guardados",
    UpdateSavedStudent: "Tu borrador ha sido guardado",
    UpdateSubmitedTeacher: "Resultados de la revisión enviados",
    UpdateSubmitedStudent: "Tus correcciones han sido enviadas",
    Checking: "En revisión",
    Done: "Completado",
    FixStudent: "Corregir",
    FixTeacher: "Hay errores",
    CheckingTime: "revisión • {{time}}",
    DoneTime: "enviado • {{time}}",
    TeacherName: "Profesor: {{name}}",
    StudentName: "Estudiante: {{name}}",
    AllStatuses: "Todos los estados",
    Checked: "Revisado",
    NewCheck: "En revisión",
    Processing: "Procesando",
    StudentSearch: "Buscar estudiante",
    TeacherSearch: "Buscar profesor",
    EmptyList: "No hay tareas adecuadas en este momento",
    TaskSubtitle: "Unidad {{unit}} • {{part}} • Ejercicio {{exercise}}",
  },
};

export default es;
