import { typecheck } from "mobx-state-tree";

import { removeEmptyValues, deflateObject } from "./basic";
import { ExerciseSnapshot, ExerciseModel } from "../models/Exercise/Exercise";

export const typecheckExercise = (exercise: ExerciseSnapshot) => {
  try {
    const deflatedObject = removeEmptyValues(deflateObject(exercise));

    typecheck(ExerciseModel, deflatedObject);

    return exercise;
  } catch (error) {
    console.error(error);

    const brokenExercise = Object.fromEntries(
      Object.entries(exercise).map(([k, v]) => {
        if (["id", "name", "blockId", "__typename", "order"].includes(k)) {
          return [k, v];
        }

        if (["questions", "questionsRaw"].includes(k)) {
          return [k, []];
        }

        if (["state", "uiType", "uiSubtype"].includes(k)) {
          return [k, undefined];
        }

        return [k, null];
      })
    ) as ExerciseSnapshot;

    brokenExercise.broken = true;

    return brokenExercise;
  }
};
