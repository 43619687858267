import { WordsResponse } from "./index";
import { api } from "../api";

export const getWords = (unitId: string) => {
  return api
    .get("/api/v4/words", {
      params: { unit_id: unitId },
    })
    .then((res) => res.data.data as WordsResponse);
};

type Language = "ru" | "en" | "uz" | "tt";

interface SynthesizeSpeechParams {
  baseUrl: string;

  text: string;
  lang: Language;
  voice?: "john" | "marina";
}

export const LANGUAGE_CODES: Record<Language, string> = {
  ru: "ru-RU",
  en: "en-EN",
  uz: "uz-UZ",
  tt: "tt-TT",
};

// Примитивный кэш чтобы не ходить каждый раз в нейронку
const cache = new Map<string, string>();
export const synthesizeSpeech = async (params: SynthesizeSpeechParams) => {
  const { baseUrl, text, lang } = params;
  let { voice } = params;

  if (cache.has(text)) {
    return cache.get(text);
  }

  if (!voice) {
    voice = lang === "en" ? "john" : "marina";
  }

  try {
    const requestData = new URLSearchParams({
      text,
      lang: LANGUAGE_CODES[lang],
      voice,
      format: "oggopus",
    });

    const response = await fetch(`${baseUrl}/synthesis`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: requestData,
    });

    if (!response.ok) {
      throw Error("Error synthesizing speech " + (await response.text()));
    }

    const audioBlob = await response.blob();
    const objectURL = URL.createObjectURL(audioBlob);

    cache.set(text, objectURL);

    return objectURL;
  } catch (error) {
    console.error(error);
  }
};

interface TranslateTextParams {
  baseUrl: string;

  text: string;
  sourceLanguageCode: Language;
  targetLanguageCode: Language;
}
export const translateText = async (params: TranslateTextParams) => {
  const { baseUrl, text, sourceLanguageCode, targetLanguageCode } = params;

  try {
    //* Через axios не работает из-за CORS
    const response = await fetch(`${baseUrl}/translate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        texts: [text],
        sourceLanguageCode,
        targetLanguageCode,
      }),
    });

    if (!response.ok) {
      throw Error("Error translate text " + (await response.text()));
    }

    const data = await response.json();

    return data.translations[0].text as string;
  } catch (error) {
    console.error(error);
  }
};
