import Reactotron from "reactotron-react-js";

import { RootStore, RootStoreModel } from "./RootStore";
import { isDev } from "../../utils/basic";
import { Environment } from "../environment";

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
const createEnvironment = async () => {
  const env = new Environment();
  await env.setup();
  return env;
};

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  let rootStore: RootStore;
  let data: Record<string, unknown>;

  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();
  try {
    data = {};
    rootStore = RootStoreModel.create(data, env);
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({}, env);

    // but please inform us what happened
  }

  // reactotron logging
  if (isDev) {
    const reactotron: any = Reactotron;
    if (reactotron?.trackMstNode) {
      reactotron.trackMstNode(rootStore);
    }
  }

  return rootStore;
}
