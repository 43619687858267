const uz = {
  Exercise: {
    Units: "Bo'limlar",
    Blocks: "Bloklar",
    Exercises: "Mashqlar",
    Unit: "Bo'lim",
    Block: "Blok",
    Exercise: "Mashq",
    Script: "Video uchun skript",
    HideScript: "Skriptni yashirish",
    ShowScript: "Skriptni ko'rsatish",
    LanguageSamples: "Til namunalar",
    Extras: "Qo'shimcha materiallar",
    ToolReload: "Yangilash",
    ToolTranslate: "Tarjima qilish",
    TranslateHelper:
      "Tarjima qilish va lug'at bilan ishlash uchun mashq ichidagi matnni tanlang",
    ToolCheck: "Tekshirish",
    ToolNext: "Keyingi",
    IsCheckedWarning: "Vazifa allaqachon tekshirilgan",
    NotFilledWarning: "Siz barcha savollarga javob bermadingiz",
    Translate: "Tarjima",
    Vocabulary: "Leksika",
    NoTranslation: "Tarjima yo'q",
    AddToDictionary: "Lug'atga qo'shish",
    SelectedTranslationsWarning: "1-3 tarjimani tanlash kerak",
    TranslateWordFailed: "So'zni tarjima qilish muvaffaqiyatsiz bo'ldi",
    AddWordsFailed: "So'zlarni qo'shish muvaffaqiyatsiz bo'ldi",
    AddWordsSuccess: "So'zlar muvaffaqiyatli qo'shildi",
    AddedBefore: "Avval qo'shilgan",
    EnterText: "Matn kiriting",
    Characters: "Belgilar",
    Words: "So'zlar",
    TextSize: "Matningizning hajmi:",
    WordsAtMinimum: "Eng kam so'zlar",
    LearnWords: "So'zlarni o'rganish",
    Ok: "Ok",
    Expand: "Kengaytirish",
    Collapse: "Yig'ish",
    Close: "Yopish",
    ResizeTooltip: "O'qish uchun kengaytirish",
    ResetChangesTooltip: "O'zgarishlarni bekor qilish",
    NoChange: "O'zgarishlar yo'q",
    CorrectAnswer: "To'g'ri javob",
    DevTooltip:
      "Ushbu mashq turi ishlab chiqilmoqda, eski interfeysga o'tish uchun",
    FollowLink: "Havolaga o'ting",
    CreativeExercisePassed: "Sizning mashqingiz tekshirish uchun yuborildi",
    CreativeExerciseVerified: "Ishingiz tekshirildi. Bosing",
    CreativeExerciseVerifiedLink: "shu yerda",
    DialogExerciseConfirmAnswer: "Tasdiqlash",
    DialogExerciseResetAnswer: "Qayta yozish",
    DialogExerciseSelectAnswer: "Mana bu",
    DialogExerciseHelperLegend: "Mashqni qanday bajarish kerak",
    DialogExerciseFinishHelperLegend: "Tabriklaymiz!",
    DialogExerciseFirstHelper:
      "Quyidagi xabarlar ro'yxatidan javobingizni aqlan tanlang, mikrofonni bosing va uni ayting.",
    DialogExerciseSecondHelper:
      "Ajoyib, tizim nutqingizni tanidi! Javobingizni tasdiqlang yoki qayta yozing.",
    DialogExerciseThirdHelper:
      "Sizning ovozli kirish urinishlaringiz tugadi, qo'lda javoblardan birini tanlang.",
    DialogExerciseFinishHelper:
      "Siz mashqni bajardingiz. Barcha dialogni tinglashingiz yoki tekshirish tugmasini bosib keyingi vazifaga o'tishingiz mumkin",
    DialogExerciseRecognitionError:
      "Afsuski tizim javobingizni taniy olmadi, qayta urinib ko'ring.",
    AudioRecorderText: "Yozish uchun bosing ({{count}}) soniya",
    RadioLineHelperTextLeft:
      "Hamma to'g'ri javoblar ko'rsatilmagan. Yana toping",
    RadioLineHelperTextRight: "to'g'ri javoblar.",
    NextDisabledWarning: "Mashqni tekshiring",
    BrokenError:
      "Ushbu mashq ishlab chiqilmoqda, uni eski interfeysda bajaring.",
    BrokenErrorLink: "Havola",
  },
  TestingCourseResult: {
    ModalTitle: "Test results",
    Confirm: "Go to results",
    ModalBodyEntrance:
      "Dear {{fullName}}, You are recommended to take a CEFR level course: {{cefrLevel}}.",
    ModalBodyFinal:
      "Dear {{fullName}}, You have completed the test with a score of {{courseMark}} out of {{maxMark}}.\nYour score is {{percentage}}%.",
    ModalBodyDefault:
      "Dear {{fullName}}, You have successfully completed testing.",
  },
  ResultsModals: {
    UnitTitle: "{{name}} boʻlimi natijalari",
    BlockTitle: "{{name}} bloklash natijalari",
    Passed: "To'g'ri: {{count}}",
    Wrong: "Xatolar: {{count}}",
    Skipped: "Yo'qolgan: {{count}}",
    Blocks: "Bloklar",
    Block: "Blok {{order}}: {{name}}",
    Exercises: "Mashqlar",
    Exercise: "{{order}} mashqi: {{name}}",
    Repeat: "Takrorlang",
    Continue: "Davom eting",
    DontShowAgain:
      "Bu oyna boshqa koʻrsatilmasin (uni profil sozlamalarida qayta yoqishingiz mumkin)",

    TestUnitTitle: "Tabriklaymiz! Siz {{order}} sinov bosqichini tugatdingiz",
    TestUnitResultTitle: "Sizning natijangiz",
    TestUnit: "{{order}} boʻlimi. {{name}}",
    TestUnitTotalProgress: "Umumiy taraqqiyot",
    TestUnitResults: "Natijalar",
    TestUnitNext: "Keyingi bosqichni boshlang",
    TestUnitScoredPoints: "{{count}}/{{total}} ball",
  },
};

export default uz;
