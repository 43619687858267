import { Instance, SnapshotOut, getRoot, types } from "mobx-state-tree";

import { ExerciseResultCheckingHistoryModel } from "./ExerciseResultCheckingHistory";
import {
  ExerciseResultCorrection,
  ExerciseResultCorrectionModel,
} from "./ExerciseResultCorrection";
import {
  ExerciseResultError,
  ExerciseResultErrorModel,
} from "./ExerciseResultError";
import { ExerciseResultPassingModel } from "./ExerciseResultPassing";
import { RootStore } from "..";
import { UpdateExerciseResult } from "../../services/Api";
import { withEnvironment } from "../extensions/withEnvironment";

export enum ExerciseResultStatus {
  NEW_CHECK = "new_check",
  PROCESSING = "processing",
  CHECKED = "checked",
}

export enum ExerciseResultType {
  WRITING = "writing",
  SPEAKING = "speaking",
}

export interface ExerciseResultUpdateData {
  errors: ExerciseResultError[];
  corrections: ExerciseResultCorrection[];
  comment: string;
}

export const ExerciseResultModel = types
  .model("ExerciseResult", {
    id: types.identifier,
    status: types.enumeration<ExerciseResultStatus>(
      "ExerciseResultStatus",
      Object.values(ExerciseResultStatus)
    ),
    type: types.enumeration<ExerciseResultType>(
      "ExerciseResultType",
      Object.values(ExerciseResultType)
    ),
    name: types.string,
    hasErrors: types.boolean,
    teacher: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.Date),
    checkedAt: types.maybeNull(types.Date),
    data: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    courseName: types.optional(types.string, ""),
    breadcrumbs: types.optional(types.array(types.string), []),
    exerciseName: types.maybeNull(types.string),
    exerciseOrder: types.maybeNull(types.number),
    student: types.optional(types.string, ""),
    errors: types.optional(types.array(ExerciseResultErrorModel), []),
    corrections: types.optional(types.array(ExerciseResultCorrectionModel), []),
    taskText: types.maybeNull(types.string),
    passings: types.optional(types.array(ExerciseResultPassingModel), []),
    checkingHistory: types.optional(
      types.array(ExerciseResultCheckingHistoryModel),
      []
    ),
    isUpdating: types.optional(types.boolean, false),
    isSubmiting: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get unit() {
      return (self.breadcrumbs[0] ?? "").replace(/(Unit|Юнит)/i, "");
    },
    get part() {
      return self.breadcrumbs[1] ?? "";
    },
    get exercise() {
      const { exerciseName, exerciseOrder } = self;

      return `${exerciseOrder ?? ""} ${exerciseName ?? ""}`;
    },
  }))
  .views((self) => ({
    getUpdateParams({
      errors,
      corrections,
      comment,
    }: ExerciseResultUpdateData): UpdateExerciseResult {
      const { auth } = getRoot<RootStore>(self);
      const { user } = auth;
      const { isTeacher } = user ?? {};
      return {
        id: self.id,
        comment,
        isTeacher: isTeacher ?? false,
        ...(isTeacher
          ? {
              errors,
              removedErrors: self.errors.filter((item) => {
                return !errors.find(
                  ({ currentId }) => currentId === item.currentId
                );
              }),
            }
          : {}),
        ...(!isTeacher
          ? {
              corrections,
              removedCorrections: self.corrections.filter((item) => {
                return !corrections.find(
                  ({ currentId }) => currentId === item.currentId
                );
              }),
            }
          : {}),
      };
    },
  }))
  .actions((self) => ({
    setIsUpdating(isUpdating: boolean) {
      self.isUpdating = isUpdating;
    },
    setIsSubmiting(isSubmiting: boolean) {
      self.isSubmiting = isSubmiting;
    },
  }))
  .actions((self) => ({
    async loadUpdates() {
      const { fetchApi, auth } = getRoot<RootStore>(self);
      const { user } = auth;
      const { isTeacher } = user ?? {};

      const { api } = self.environment;

      const params = { id: self.id, isTeacher: isTeacher ?? false };
      await fetchApi((token) =>
        api.getExerciseResultDetails(params, token, true)
      );
    },
  }))
  .actions((self) => ({
    async update(params: ExerciseResultUpdateData) {
      self.setIsUpdating(true);
      await self.environment.api.updateExerciseResult(
        self.getUpdateParams(params)
      );
      await self.loadUpdates();
      self.setIsUpdating(false);
    },
    async updateAndSubmit(params: ExerciseResultUpdateData) {
      self.setIsSubmiting(true);
      await self.environment.api.updateExerciseResult({
        ...self.getUpdateParams(params),
        isSubmit: true,
      });
      await self.loadUpdates();
      self.setIsSubmiting(false);
    },
  }));

type ExerciseResultModelType = Instance<typeof ExerciseResultModel>;
type ExerciseResultModelTypeSnapshotType = SnapshotOut<
  typeof ExerciseResultModel
>;

export type ExerciseResult = ExerciseResultModelType;
export type ExerciseResultSnapshot = ExerciseResultModelTypeSnapshotType;
