import { useState, useCallback } from "react";

import { styled } from "@mui/material/styles";

import { Icon } from "components/Icon";
import { currentLang, switchLang } from "locales/i18n";

const TranslateContentWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  transform: "translateY(3px)",

  color: "#E0E0E0",

  svg: {
    marginLeft: "0.7rem",
  },
  ".active": {
    color: "#FF6161",
  },

  "&.menu": {
    color: "#000",
  },
}));

const Title = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  fontSize: "12px",
  lineHeight: "22px",
  marginLeft: "0.8rem",
  cursor: "pointer",
}));

export const LanguageSwitcher = ({
  variant = "header",
}: {
  variant?: "header" | "menu";
}): JSX.Element => {
  const [language, setLanguage] = useState(currentLang());

  const languageSwitchHandler = useCallback(() => {
    if (language === "en") {
      setLanguage("ru");
      switchLang("ru");
    } else {
      setLanguage("en");
      switchLang("en");
    }
  }, [language]);

  return (
    <TranslateContentWrapper className={variant === "menu" ? "menu" : ""}>
      <Title
        onClick={languageSwitchHandler}
        className={language === "ru" ? "active" : ""}
      >
        RUS
      </Title>
      <Title
        onClick={languageSwitchHandler}
        className={language === "en" ? "active" : ""}
      >
        ENG
      </Title>
      {variant === "header" && (
        <Icon type="translationIcon" width={22} height={19} />
      )}
    </TranslateContentWrapper>
  );
};
