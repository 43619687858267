const es = {
  Classes: {
    SampleByStatus: "Muestra por estado de las clases durante el período",
    OnlyLessonsWithoutGroup: "Solo clases sin grupos",
    Title: "Clases",
    Company: "Empresa",
    Group: "Grupo",
    Status: "Estado",
    ClassIndex: "Clase",
    ClassDate: "Fecha de la clase",
    Teacher: "Profesor",
    None: "Nada seleccionado",
    Process: "Procesar",
    DateRangeError:
      "Para procesar, debe seleccionar la fecha de inicio y la fecha de finalización.",
    NoDataError: "No hay datos para procesar.",
    StandardHelper: "Para mostrar datos, utilice al menos un filtro.",
    ProcessHelper: "Para mostrar datos, seleccione una fecha.",
    Processing: "Los datos se están procesando",
    Type: "Tipo",
    Unit: "Sección",
    Duration: "Horas académicas",
  },
  ClassesDownload: {
    Download: "Descargar",
    Classes: "Clases",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Ingrese el nombre del grupo",
    SelectGroup: "Seleccionar grupo",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "No tienes módulos de estudio asignados.",
    SecondTextDefault: "Contacta a tu coordinador de formación.",
    FirstTextDistributor: "No hay datos para mostrar.",
    SecondTextDistributor: "Intenta ingresar algún dato.",
    FirstTextClasses: "No hay datos para mostrar.",
    SecondTextClasses: "Intenta cambiar los filtros o contacta con soporte.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Profesores no encontrados",
    SelectTeacher: "Seleccionar profesor",
  },
  Loading: {
    Error: "Error de carga, intente actualizar la página",
  },
  DateRangePicker: {
    StartDate: "Fecha de inicio",
    EndDate: "Fecha de finalización",
  },
  DatePicker: {
    MaskPlaceholder: "dd/mm/yyyy",
    Today: "Hoy",
    Clear: "Borrar",
  },
  LessonStatuses: {
    planned: "Planificado",
    completed: "Completado",
    studentLateCancellation: "Cancelación tardía por parte de los estudiantes",
    earlyCanceledByCustomer:
      "Cancelación anticipada por parte de los estudiantes",
    earlyCanceledBySchool: "Cancelación anticipada por parte de la escuela",
    lateCanceledByCustomer: "Cancelación tardía por parte de los estudiantes",
    lateCanceledBySchool: "Cancelación tardía por parte de la escuela",
    notPerformedToPay: "Para pago",
  },
};

export default es;
