/* eslint-disable @typescript-eslint/no-empty-interface */
import { Paper, PaperProps, styled } from "@mui/material";

export interface StyledPaperProps extends PaperProps {}

export const StyledPaper = styled(Paper)<StyledPaperProps>(({ theme }) => ({
  borderRadius: theme.spacing(3),
  boxShadow: `0px 5px 50px rgba(0, 67, 101, 0.08), 0px 2px 6px rgba(130, 169, 188, 0.08)`,
  display: "flex",
  justifyContent: "center",
}));
