const ar = {
  Classes: {
    SampleByStatus: "عينة حسب حالة الحضور خلال الفترة",
    OnlyLessonsWithoutGroup: "فقط الدروس بدون مجموعة",
    Title: "الدروس",
    Company: "الشركة",
    Group: "المجموعة",
    Status: "الحالة",
    ClassIndex: "الدرس",
    ClassDate: "تاريخ الدرس",
    Teacher: "المعلم",
    None: "لا يوجد",
    Process: "معالجة",
    DateRangeError: "يجب عليك اختيار تاريخ بداية وتاريخ نهاية للمعالجة.",
    NoDataError: "لا توجد بيانات للمعالجة.",
    StandardHelper: "لعرض البيانات، استخدم مرشحًا واحدًا على الأقل.",
    ProcessHelper: "لعرض البيانات، اختر التاريخ.",
    Processing: "جاري معالجة البيانات",
    Type: "النوع",
    Unit: "الوحدة",
    Duration: "ساعات أكاديمية",
  },
  ClassesDownload: {
    Download: "تنزيل",
    Classes: "الدروس",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "لا توجد مجموعات لك.",
    SecondTextDefault: "يرجى الاتصال بمنسق دراستك.",
    FirstTextDistributor: "لا توجد بيانات لعرضها.",
    SecondTextDistributor: "حاول إدخال بعض البيانات.",
    FirstTextClasses: "لا توجد بيانات لعرضها.",
    SecondTextClasses: "حاول تغيير المرشحات أو الاتصال بالدعم.",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "أدخل اسم المجموعة",
    SelectGroup: "اختر المجموعة",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "لم يتم العثور على معلمين",
    SelectTeacher: "اختر المعلم",
  },
  Loading: {
    Error: "خطأ في التحميل، حاول تحديث الصفحة",
  },
  DateRangePicker: {
    StartDate: "تاريخ البداية",
    EndDate: "تاريخ النهاية",
  },
  DatePicker: {
    MaskPlaceholder: "dd/mm/yyyy",
    Today: "اليوم",
    Clear: "مسح",
  },
  LessonStatuses: {
    planned: "مخطط",
    completed: "مكتمل",
    earlyCanceledByCustomer: "إلغاء مبكر من الطالب",
    earlyCanceledBySchool: "إلغاء مبكر من المدرسة",
    lateCanceledByCustomer: "إلغاء متأخر من الطالب",
    lateCanceledBySchool: "إلغاء متأخر من المدرسة",
    notPerformedToPay: "غير منفذ للدفع",
  },
};

export default ar;
