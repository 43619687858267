import { useCallback, useState } from "react";

interface usePopoverParams {
  onOpen?: () => void;
}

export const usePopover = (params: usePopoverParams) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = useCallback((event?: any) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
    setOpen(true);
    params?.onOpen?.();
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  return {
    open,
    anchorEl,
    handleOpen,
    handleClose,
  };
};
