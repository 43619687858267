const en = {
  CreativeTasks: {
    Title: "Creative tasks",
    UpdateTaskTeacher: "Save",
    SubmitTaskTeacher: "Send results to student",
    UpdateTaskStudent: "Save draft",
    SubmitTaskStudent: "Save and submit for review",
    AnswerHeaderStatistics:
      "Questions: {{questions}} | Symbols: {{symbols}} | Words: {{words}} | Sentences: {{sentenses}}",
    QuestionsAndComments: "Errors and comments:",
    HoverForDisplay: "Hover to display:",
    CheckingHistory: "Checking history",
    CurrentVersion: "Current version",
    TeacherComment: "Comment on the work from the teacher:",
    CommentPlaceholder: "You can leave a comment on the work here",
    SaveCorrection: "Save",
    CancelCorrection: "Cancel",
    Grammar: "Grammar",
    Lexical: "Vocabulary",
    Comment: "Comment",
    ErrorRangeFilled: "You have filled all error types in the selected range",
    SaveError: "Save",
    GrammarErrorTitle: "Grammar",
    ResultTitle: "Results of the creative task",
    Student: "Student",
    Teacher: "Teacher",
    CurrentVersionTime: "Current version: {{time}}",
    TaskText: "Task",
    HasErrors: "There are errors",
    WithoutErrors: "Executed without errors",
    UpdateSavedTeacher: "Your changes have been saved",
    UpdateSavedStudent: "Your draft has been saved",
    UpdateSubmitedTeacher: "Check results have been sent",
    UpdateSubmitedStudent: "Your edits have been sent",
    Checking: "Under check",
    Done: "Done",
    FixStudent: "Fix",
    FixTeacher: "There are errors",
    CheckingTime: "checking • {{time}}",
    DoneTime: "done • {{time}}",
    TeacherName: "Teacher: {{name}}",
    StudentName: "Student: {{name}}",
    AllStatuses: "All statuses",
    Checked: "Checked",
    NewCheck: "Under check",
    Processing: "Processing",
    StudentSearch: "Search for a student",
    TeacherSearch: "Search for a teacher",
    EmptyList: "There are no suitable tasks at the moment",
    TaskSubtitle: "Unit {{unit}} • {{part}} • Exercise {{exercise}}",
  },
};

export default en;
