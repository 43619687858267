import { useCallback, useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";

import { ScriptItem } from "@packages/store/models/AdditionalContent/ScriptItem";
import { Block } from "@packages/store/models/Block/Block";
import { Course } from "@packages/store/models/Course/CourseModel";
import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { Unit } from "@packages/store/models/Unit/UnitModel";

import { StyledContentView } from "./styledComponents/StyledContentView";
import { AdditionalContentView } from "../AdditionalContentView";
import { MainContent } from "../MainContent";
import { VideoPlayerType } from "../VideoPlayer";

interface ContentViewProps {
  course: Course | undefined;
  unit: Unit | undefined;
  block: Block | undefined;
  exercise: Exercise | undefined;
  onNextExercise: () => void;
  onPrevExercise: () => void;
  onCheck: () => void;

  showAdditionalContentWhenEmpty: boolean;
}

export const ContentView = observer(
  ({
    course,
    unit,
    block,
    exercise,
    onNextExercise,
    onPrevExercise,
    onCheck,
    showAdditionalContentWhenEmpty,
  }: ContentViewProps): JSX.Element | null => {
    const [currentScriptItem, setCurrentScriptItem] = useState("");
    const [scriptHidden, setScriptHidden] = useState(true);

    const playerRef = useRef<VideoPlayerType | null>(null);

    const { additionalContent: blockAdditionalContent } = block || {};

    const { additionalContent: allExerciseAdditionalContent } = exercise || {};

    const exerciseVideoAdditionalContent = allExerciseAdditionalContent?.find(
      (content) => content.isVideo
    );

    let videoAdditionalContent =
      exerciseVideoAdditionalContent ?? blockAdditionalContent;

    let needToShowVideo = Boolean(videoAdditionalContent?.isVideo);

    if (exercise?.isDialog) {
      videoAdditionalContent = blockAdditionalContent;
      needToShowVideo = Boolean(
        blockAdditionalContent?.isVideo && exercise?.isFilled
      );
    }

    const { videoTracks = [], script } = videoAdditionalContent || {};

    const handleScriptItemChange = useCallback((scriptItem: ScriptItem) => {
      const player = playerRef.current;

      if (player && scriptItem.timeStart) {
        player.currentTime(scriptItem.timeStart);
      }

      setCurrentScriptItem(scriptItem.id);
    }, []);

    const handleTimeUpdate = useCallback(() => {
      const player = playerRef.current;

      if (player) {
        const interval = player.currentTime();

        const { id: scriptItemId } =
          videoAdditionalContent?.script?.find(
            (item) =>
              Number(item.timeStart) < interval &&
              Number(item.timeEnd) > interval
          ) || {};

        if (scriptItemId && scriptItemId !== currentScriptItem) {
          setCurrentScriptItem(scriptItemId);
        }
      }
    }, [videoAdditionalContent, currentScriptItem]);

    const handleEnded = useCallback(() => {
      setCurrentScriptItem("");
    }, []);

    const handlePlayerReady = useCallback(
      (player: VideoPlayerType) => {
        playerRef.current = player;

        player.on("timeupdate", handleTimeUpdate);

        player.on("ended", handleEnded);
      },
      [handleEnded, handleTimeUpdate]
    );

    const handleScriptHiddenToggle = useCallback(() => {
      setScriptHidden((prev) => !prev);
    }, []);

    // Сброс логики скрипта при переключении упражнения
    useEffect(() => {
      setScriptHidden(true);
      setCurrentScriptItem("");
    }, [exercise]);

    return (
      <StyledContentView>
        <AdditionalContentView
          course={course}
          unit={unit}
          block={block}
          exercise={exercise}
          script={script ?? []}
          scriptHidden={scriptHidden}
          currentScriptItem={currentScriptItem}
          onScriptItemChange={handleScriptItemChange}
          showWhenEmpty={showAdditionalContentWhenEmpty}
        />

        <MainContent
          course={course}
          exercise={exercise}
          hasScript={Boolean(script)}
          scriptHidden={scriptHidden}
          needToShowVideo={needToShowVideo}
          videoTracks={videoTracks}
          onScriptHiddenToggle={handleScriptHiddenToggle}
          onPlayerReady={handlePlayerReady}
          onNextExercise={onNextExercise}
          onPrevExercise={onPrevExercise}
          onCheck={onCheck}
        />
      </StyledContentView>
    );
  }
);
