const ru = {
  CreativeTasks: {
    Title: "Творческие задания",
    UpdateTaskTeacher: "Сохранить",
    SubmitTaskTeacher: "Отправить результаты",
    UpdateTaskStudent: "Сохранить черновик",
    SubmitTaskStudent: "Сохранить и отправить на проверку",
    AnswerHeaderStatistics:
      " Вопросов: {{questions}} | Символов: {{symbols}} | Слов: {{words}} | Предложений: {{sentenses}}",
    QuestionsAndComments: "Ошибки и комментарии:",
    HoverForDisplay: "Наведите для отображения:",
    CheckingHistory: "История проверок",
    CurrentVersion: "Текущая версия",
    TeacherComment: "Комментарий к работе от преподавателя:",
    CommentPlaceholder: "Вы можете оставить комментарий к работе здесь",
    SaveCorrection: "Сохранить",
    CancelCorrection: "Отмена",
    Grammar: "Грамматика",
    Lexical: "Лексика",
    Comment: "Комментарий",
    ErrorRangeFilled: "Вы использовали все типы ошибок на выделенном диапазоне",
    SaveError: "Сохранить",
    GrammarErrorTitle: "Грамматика",
    ResultTitle: "Результаты выполнения творческого задания",
    Student: "Студент",
    Teacher: "Преподаватель",
    CurrentVersionTime: "Текущая версия: {{time}}",
    TaskText: "Задание",
    HasErrors: "Имеются ошибки",
    WithoutErrors: "Выполнено без ошибок",
    UpdateSavedTeacher: "Ваши изменения сохранены",
    UpdateSavedStudent: "Ваш черновик сохранен",
    UpdateSubmitedTeacher: "Результаты проверки отправлены",
    UpdateSubmitedStudent: "Ваши правки отправлены",
    Checking: "На проверке",
    Done: "Выполнено",
    FixStudent: "Исправить",
    FixTeacher: "Есть ошибки",
    CheckingTime: "проверка • {{time}}",
    DoneTime: "отправлено • {{time}}",
    TeacherName: "Преподаватель: {{name}}",
    StudentName: "Студент: {{name}}",
    AllStatuses: "Все статусы",
    Checked: "Проверено",
    NewCheck: "На проверке",
    Processing: "Обрабатывается",
    StudentSearch: "Поиск студента",
    TeacherSearch: "Поиск преподавателя",
    EmptyList: "Нет подходящих заданий на данный момент",
    TaskSubtitle: "Раздел {{unit}} • {{part}} • Упражнение {{exercise}}",
  },
};

export default ru;
