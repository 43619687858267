import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Checkbox } from "components/Checkbox";
import { Icon } from "components/Icon";
import { TooltipCustom } from "components/Tooltip";

import {
  IconBox,
  DesktopContainer,
  TabletContainer,
  WordContainer,
  WordGroup,
  WordSection,
} from "./styledComponents";

export interface WordProps {
  word?: string;
  translation?: string;
  translated?: boolean;
  learned?: boolean;
  onClickLanguage?: () => void;
  onClickBookmark?: () => void;
  onClickVoice?: () => void;
  onClickCheck?: () => void;
  onClickTrash?: () => void;
}

export const WordCard = ({
  word,
  translation,
  translated,
  learned,
  onClickLanguage,
  onClickBookmark,
  onClickVoice,
  onClickTrash,
}: WordProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const LangIcon = (
    <IconBox onClick={onClickLanguage}>
      <Icon type={translated ? "ruLang" : "engLang"} width={14} height={14} />
    </IconBox>
  );

  const BookmarkIcon = (
    <IconBox onClick={onClickBookmark}>
      <Icon type="bookOutline" width={14} height={14} />
    </IconBox>
  );

  const TrashIcon = (
    <TooltipCustom
      tooltipText={learned ? t("MyWords:AlreadyLearned") : t("MyWords:Learn")}
      type="default"
    >
      <Box>
        <Checkbox
          sx={{ p: 0 }}
          checkedIconColor={theme.palette.success.main}
          checked={learned}
          onClick={onClickTrash}
        />
      </Box>
    </TooltipCustom>
  );

  const VoiceIcon = (
    <IconBox onClick={onClickVoice}>
      <Icon type="bxVolumeFull" width={14} height={14} />
    </IconBox>
  );
  const Word = (
    <Typography fontSize="0.8rem" maxHeight="2.3rem" overflow="hidden">
      {translated ? translation : word}
    </Typography>
  );
  return (
    <WordContainer>
      <DesktopContainer>
        <WordSection>
          <WordGroup>
            {LangIcon}
            {BookmarkIcon}
          </WordGroup>
        </WordSection>
        <WordSection alignItems="flex-end">
          <WordGroup>
            {Word}
            {VoiceIcon}
          </WordGroup>
          {TrashIcon}
        </WordSection>
      </DesktopContainer>

      <TabletContainer>
        <WordSection>
          <WordGroup>
            {LangIcon}
            {BookmarkIcon}
          </WordGroup>
          {TrashIcon}
        </WordSection>
        <WordSection alignItems="flex-end">
          {Word}
          {VoiceIcon}
        </WordSection>
      </TabletContainer>
    </WordContainer>
  );
};
