import { forwardRef, RefObject } from "react";

import {
  Checkbox as MaterialUICheckbox,
  CheckboxProps as MaterialCheckboxProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon } from "components/Icon/Icon";

export type CheckboxProps = MaterialCheckboxProps & {
  forwardedRef?: RefObject<HTMLButtonElement>;
  checkedIconColor?: string;
};

const MaterialCheckbox = styled(MaterialUICheckbox)({});

export const Checkbox = forwardRef(
  (props: CheckboxProps): JSX.Element => (
    <MaterialCheckbox
      {...props}
      ref={props.forwardedRef}
      icon={
        <Icon
          type="exerciseCheckBtnOff"
          stroke="white"
          width={16}
          height={16}
        />
      }
      checkedIcon={
        <Icon
          type="exerciseCheckBtnOn"
          stroke="white"
          width={16}
          height={16}
          color={props.checkedIconColor}
        />
      }
    />
  )
);
