const en = {
  Exercise: {
    Units: "Units",
    Blocks: "Blocks",
    Exercises: "Exercises",
    Unit: "Unit",
    Block: "Block",
    Exercise: "Exercise",
    Script: "Video Script",
    HideScript: "Hide script",
    ShowScript: "Show script",
    LanguageSamples: "Language Samples",
    Extras: "Extras",
    ToolReload: "Reload",
    ToolTranslate: "Translate",
    TranslateHelper:
      "Select the text within the exercise to translate and work with the dictionary",
    ToolCheck: "Check",
    ToolNext: "Next",
    IsCheckedWarning: "The task has already been checked",
    NotFilledWarning: "You didn't answer all of the questions",
    Translate: "Translate",
    Vocabulary: "Vocabulary",
    NoTranslation: "There is no translation",
    AddToDictionary: "Add to Dictionary",
    SelectedTranslationsWarning: "You need to choose 1-3 translations",
    TranslateWordFailed: "Failed to translate word",
    AddWordsFailed: "Failed to add words",
    AddWordsSuccess: "The words were successfully added",
    AddedBefore: "Was added before",
    EnterText: "Write here",
    Characters: "Letters",
    Words: "Words",
    // TextSize: "Text size",
    TextSize: "Your text volume:",
    WordsAtMinimum: "Words at minimum",
    LearnWords: "Learn words",
    Ok: "Ok",
    Expand: "Expand",
    Collapse: "Collapse",
    Close: "Close",
    ResizeTooltip: "Stretch to read",
    ResetChangesTooltip: "Reset all changes",
    NoChange: "No changes",
    CorrectAnswer: "Correct answer",
    DevTooltip:
      "This type of exercise is in development, to switch to the old interface",
    FollowLink: "Follow this link",
    CreativeExercisePassed: "Your work has been sent to your teacher",
    CreativeExerciseVerified: "Your work has been checked. Click",
    CreativeExerciseVerifiedLink: "here",
    DialogExerciseConfirmAnswer: "Confirm",
    DialogExerciseResetAnswer: "Re-record",
    DialogExerciseSelectAnswer: "This",
    DialogExerciseHelperLegend: "How to do the exercise",
    DialogExerciseFinishHelperLegend: "Congratulations!",
    DialogExerciseFirstHelper:
      "Mentally select your answer from the list of messages below, click on the microphone and speak it.",
    DialogExerciseSecondHelper:
      "Great, the system recognized your speech! Please confirm your answer or re-record it.",
    DialogExerciseThirdHelper:
      "You have run out of voice input attempts, select one of the answer options manually.",
    DialogExerciseFinishHelper:
      // eslint-disable-next-line max-len
      "You have completed the exercise. You can listen to the entire dialogue or go to the next exercise by clicking on the Check button",
    DialogExerciseRecognitionError:
      "Unfortunately, the system could not recognize your answer, please try again.",
    AudioRecorderText: "Click to record ({{count}}) sec",
    RadioLineHelperTextLeft: "Not all correct answers are given. Find",
    RadioLineHelperTextRight: "more correct answers.",
    NextDisabledWarning: "Check the exercise",
    BrokenError:
      "This exercise is under development, please complete it in the old interface.",
    BrokenErrorLink: "Link",
  },
  ResultsModals: {
    UnitTitle: "Unit results {{name}}",
    BlockTitle: "Block results {{name}}",
    Passed: "Passed: {{count}}",
    Wrong: "Wrong: {{count}}",
    Skipped: "Skipped: {{count}}",
    Blocks: "Blocks",
    Block: "Block {{order}}: {{name}}",
    Exercises: "Exercises",
    Exercise: "Exercise {{order}}: {{name}}",
    Repeat: "Repeat",
    Continue: "Continue",
    DontShowAgain:
      "Do not show this window anymore (you can turn it back on in your profile settings)",

    TestUnitTitle:
      "Congratulations! You have completed the {{order}} testing phase",
    TestUnitResultTitle: "Your result is",
    TestUnit: "Unit {{order}}. {{name}}",
    TestUnitTotalProgress: "Overall progress",
    TestUnitResults: "Results",
    TestUnitNext: "Start next stage",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} points",
    TestUnitScoredPoints_one: "{{count}}/{{total}} point",
    TestUnitScoredPoints_other: "{{count}}/{{total}} points",

    TestCourseTitle: "Congratulations! You have finished testing",
    TestCourseRecomendedLevel:
      "The program for the level is recommended for you -",
    TestCourseTotalProgress: "Overall progress",
    TestCourseResultProgress: "Results",
    TestCourseShowResults: "View results",
    TestCourseShowHourse: "Number of academic hours",
    TestCourseHint:
      "Emphasis on the development of all language skills (speaking, listening, reading and writing)",
    TestCourseA1Long:
      "I understand the main meaning of the conversation. I can describe my friends and family",
    TestCourseA2Long:
      "I understand individual sentences and can talk about everyday topics",
    TestCourseB1Long:
      "I can communicate in most situations using all tenses of verbs",
    TestCourseB2Long: "I can defend my point of view, negotiate and correspond",
    TestCourseC1Long: "I read literature fluently and can give presentations",
    TestCourseC2Long:
      "I communicate on any topic at a level close to a native speaker",
    TestCourseA1Short: "Вeginner, Elementary",
    TestCourseA2Short: "Pre-Intermediate",
    TestCourseB1Short: "Intermediate",
    TestCourseB2Short: "Upper Intermediate",
    TestCourseC1Short: "Advanced",
    TestCourseC2Short: "Upper Advanced",
  },
  TestingCourseResult: {
    ModalTitle: "Test results",
    Confirm: "Go to results",
    ModalBodyEntrance:
      "Dear {{fullName}}, You are recommended to take a CEFR level course: {{cefrLevel}}.",
    ModalBodyFinal:
      "Dear {{fullName}}, You have completed the test with a score of {{courseMark}} out of {{maxMark}}.\nYour score is {{percentage}}%.",
    ModalBodyDefault:
      "Dear {{fullName}}, You have successfully completed testing.",
  },
};

export default en;
