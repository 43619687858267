const ar = {
  CreativeTasks: {
    Title: "المهام الإبداعية",
    UpdateTaskTeacher: "حفظ",
    SubmitTaskTeacher: "إرسال النتائج إلى الطالب",
    UpdateTaskStudent: "حفظ المسودة",
    SubmitTaskStudent: "حفظ وتقديم للمراجعة",
    AnswerHeaderStatistics:
      "الأسئلة: {{questions}} | الرموز: {{symbols}} | الكلمات: {{words}} | الجمل: {{sentenses}}",
    QuestionsAndComments: "الأخطاء والتعليقات:",
    HoverForDisplay: "مرر لعرض:",
    CheckingHistory: "تاريخ المراجعة",
    CurrentVersion: "الإصدار الحالي",
    TeacherComment: "تعليق على العمل من المعلم:",
    CommentPlaceholder: "يمكنك ترك تعليق على العمل هنا.",
    SaveCorrection: "حفظ",
    CancelCorrection: "إلغاء",
    Grammar: "القواعد",
    Lexical: "المفردات",
    Comment: "تعليق",
    ErrorRangeFilled: "لقد قمت بملء جميع أنواع الأخطاء في النطاق المحدد",
    SaveError: "حفظ",
    GrammarErrorTitle: "الأخطاء النحوية",
    ResultTitle: "نتائج المهمة الإبداعية",
    Student: "الطالب",
    Teacher: "المعلم",
    CurrentVersionTime: "الإصدار الحالي: {{time}}",
    TaskText: "المهمة",
    HasErrors: "هناك أخطاء",
    WithoutErrors: "تم التنفيذ بدون أخطاء",
    UpdateSavedTeacher: "تم حفظ التغييرات الخاصة بك",
    UpdateSavedStudent: "تم حفظ المسودة الخاصة بك",
    UpdateSubmitedTeacher: "تم إرسال نتائج المراجعة.",
    UpdateSubmitedStudent: "تم إرسال تعديلاتك.",
    Checking: "قيد المراجعة",
    Done: "تم",
    FixStudent: "إصلاح",
    FixTeacher: "هناك أخطاء",
    CheckingTime: "قيد المراجعة • {{time}}",
    DoneTime: "تم • {{time}}",
    TeacherName: "المعلم: {{name}}",
    StudentName: "الطالب: {{name}}",
    AllStatuses: "جميع الحالات",
    Checked: "تم المراجعة",
    NewCheck: "قيد المراجعة",
    Processing: "جاري المعالجة",
    StudentSearch: "البحث عن طالب",
    TeacherSearch: "البحث عن معلم",
    EmptyList: "لا توجد مهام مناسبة في الوقت الحالي",
    TaskSubtitle: "وحدة {{unit}} • {{part}} • يمارس {{exercise}}",
  },
};

export default ar;
