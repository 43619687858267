import { Box, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { GroupCell } from "./styledComponents/GroupCell";
import { AttendanceSmall } from "../../assets/icons";
import { formatGroups } from "../../helpers";
import { Table } from "../Table";
import { TooltipTeachers } from "../TooltipTeachers";

interface GroupListProps {
  data: ReturnType<typeof formatGroups>;
  showReport: (index: string) => () => void;
  showAttendance: (groupId: string) => () => void;
}

export const GroupList = observer(
  ({ data, showReport, showAttendance }: GroupListProps): JSX.Element => {
    const { t } = useTranslation();

    type TeacherGroup = {
      fullName: string;
    };

    const formatTeachers = (teachers: TeacherGroup[]) => {
      if (!teachers || !teachers.length) return "";

      const [firstName = "", lastName = ""] = teachers[0].fullName.split(" ");

      return `${lastName} ${firstName?.[0]}. ${
        teachers.length > 1 ? `+${teachers.length - 1}` : ""
      }`;
    };

    const formatDuration = (duration: number | string | undefined) => {
      if (duration === "-") return "-";
      return Number(duration ?? 0).toFixed(2);
    };

    const renderAttendanceIcon = (dates: string, groupId: string) => {
      if (dates === "-") return "-";
      return <AttendanceSmall onClick={showAttendance(groupId)} />;
    };

    const columns = [
      {
        Header: t("GroupList:group"),
        accessor: "group",
        filter: "group",
        Cell: ({ row, value }: Cell<Lesson, string>) => (
          <GroupCell
            variant="buttonUnderlined"
            onClick={showReport(row.original.id)}
          >
            {value}
          </GroupCell>
        ),
      },
      {
        Header: t("GroupList:teachers"),
        accessor: "teachers",
        Cell: ({ value }: Cell<Lesson, []>) => (
          <Tooltip
            placement="bottom"
            title={
              <TooltipTeachers
                dataTeachers={value.map((teacher: TeacherGroup) => ({
                  name: teacher.fullName,
                  post: `${t("ModalGroupReport:Teacher")}`,
                }))}
              />
            }
          >
            <GroupCell>{formatTeachers(value)}</GroupCell>
          </Tooltip>
        ),
      },
      {
        Header: t("GroupList:dates"),
        accessor: "dates",
        filter: "dates",
        Cell: ({ row, value }: Cell<Lesson, string>) => (
          <GroupCell onClick={showReport(row.original.id)}>{value}</GroupCell>
        ),
      },
      {
        Header: t("GroupList:lessonsCount"),
        accessor: "lessonsCount",
        Cell: ({ value, row }: Cell<Lesson, number>) => (
          <GroupCell onClick={showReport(row.original.id)}>{value}</GroupCell>
        ),
      },
      {
        Header: t("GroupList:total"),
        accessor: "total",
        Cell: ({ value, row }: Cell<Lesson, string>) => (
          <GroupCell onClick={showReport(row.original.id)}>
            {formatDuration(value)}
          </GroupCell>
        ),
      },
      {
        Header: t("GroupList:finished"),
        accessor: "finished",
        Cell: ({ value, row }: Cell<Lesson, string>) => (
          <GroupCell onClick={showReport(row.original.id)}>
            {formatDuration(value)}
          </GroupCell>
        ),
      },
      {
        Header: t("GroupList:left"),
        accessor: "left",
        Cell: ({ value, row }: Cell<Lesson, string>) => (
          <GroupCell onClick={showReport(row.original.id)}>
            {formatDuration(value)}
          </GroupCell>
        ),
      },
      {
        Header: t("GroupList:attendance"),
        accessor: "attendance",
        Cell: ({ row }: Cell<Lesson, string>) => (
          <GroupCell>
            {renderAttendanceIcon(row.values.dates, row.original.id)}
          </GroupCell>
        ),
      },
    ];

    return (
      <Box>
        <Table columns={columns} data={data} count={10} containerType="blue" />
      </Box>
    );
  }
);
