import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import {
  useMediaQuery,
  useTheme,
  ThemeProvider,
  CssBaseline,
} from "@mui/material";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { I18nextProvider } from "react-i18next";

import { defaultTheme } from "@packages/shared/themes/default";
import { useCancelableFetch } from "@packages/store/hooks";
import { useStores } from "@packages/store/models";
import { ExerciseAnswerModel } from "@packages/store/models/ExerciseAnswer/ExerciseAnswer";

import { BrokenExercise } from "./components/BrokenExercise";
import { ContentContainer } from "./components/ContentContainer";
import { ContentView } from "./components/ContentView";
import { DragDropProvider } from "./components/DragDropProvider/DragDropProvider";
import { Loading } from "./components/Loading";
import { NavigationPanel } from "./components/NavigationPanel";
import { ModalBlockResult } from "./components/ResultModals/ModalBlockResult";
import { ModalTestResult } from "./components/ResultModals/ModalTestResult";
import { ModalTestUnitResult } from "./components/ResultModals/ModalTestUnitResult";
import { ModalUnitResult } from "./components/ResultModals/ModalUnitResult";
import { TranslateModeProvider } from "./components/TranslateModeProvider";
import { TranslationButton } from "./components/TranslationButton";
import { TranslationMenu } from "./components/TranslationMenu";
import { mobile } from "./constants/styles";
import { getCourseUrl, getUnitUrl } from "./helpers/exerciseUrlGetters";
import { isTouchDevice } from "./helpers/isTouchDevice";
import i18n from "./locales/i18n";

interface ExerciseProps {
  courseId?: string;
  unitId?: string;
  blockId?: string;
  exerciseId?: string;
  setBlockId: (blockId: string) => void;
  setExerciseId: (exerciseId: string) => void;
  // useParams: <T>() => any;
  // useQueryParam: (name: string) => any;
  navigate: (path: string) => any;

  showExercisesResults: boolean;
  showAdditionalContentWhenEmpty?: boolean;
}

export const Exercise = observer(
  ({
    courseId,
    unitId,
    blockId,
    exerciseId,
    setBlockId,
    setExerciseId,
    navigate,
    showExercisesResults,
    showAdditionalContentWhenEmpty = true,
  }: ExerciseProps): JSX.Element | null => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobile));

    const { fetch: fetchCourses, loading: loadingCourses } =
      useCancelableFetch();
    const { fetch: fetchPassings, loading: loadingPassings } =
      useCancelableFetch();
    const { fetch: fetchCourse } = useCancelableFetch();
    const { fetch: fetchUnits } = useCancelableFetch();

    const [isCourseResultOpen, setIsCourseResultOpen] = useState(false);
    const [isTestUnitResultOpen, setIsTestUnitResultOpen] = useState(false);
    const [isUnitResultOpen, setIsUnitResultOpen] = useState(false);
    const [isBlockResultOpen, setIsBlockResultOpen] = useState(false);

    const rootStore = useStores();

    const {
      course: courseStore,
      unit: unitStore,
      block: blockStore,
      exerciseAnswer: exerciseAnswerStore,
      auth,
      api,
    } = rootStore;

    const { user } = auth;

    const { isHR = false } = user ?? {};

    const currentCourse = courseStore.getCourse(courseId);

    const currentUnit = unitStore.getUnit(unitId);

    const currentBlock =
      currentUnit?.getBlock(blockId) || currentUnit?.firstBlock;

    const currentExercise =
      currentBlock?.getExercise(exerciseId) || currentBlock?.firstExercise;

    const linkToOldInterface = `/learn/courses/${courseId}/units/${unitId}?block_id=${blockId}&exercise_id=${exerciseId}`;

    useEffect(() => {
      fetchCourses((token) => api.getCourses(token));
    }, [api, fetchCourses]);

    useEffect(() => {
      if (!courseId) {
        return;
      }
      fetchCourse((token) => api.getCourse(courseId, token));
    }, [fetchCourse, api, courseId]);

    useEffect(() => {
      if (!courseId) {
        return;
      }
      fetchUnits((token) => api.getUnitsByCourse(courseId, token));
    }, [fetchUnits, api, courseId]);

    useEffect(() => {
      if (isHR || !courseId) {
        return;
      }
      fetchPassings((token) => api.getPassings(courseId, token));
    }, [api, fetchPassings, courseId, isHR]);

    useEffect(() => {
      const loadUnit = async (id: string) => {
        await unitStore.loadUnit(id);
      };

      if (unitId) {
        loadUnit(unitId);
      }
    }, [unitId, unitStore]);

    useEffect(() => {
      const loadBlock = async (id: string) => {
        await blockStore.loadBlock(id);
      };

      if (currentBlock) {
        loadBlock(currentBlock.id);
      }
    }, [currentBlock, blockStore]);

    useLayoutEffect(() => {
      if (currentBlock && blockId !== currentBlock.id) {
        setBlockId(currentBlock.id);
      }
    }, [currentBlock, blockId, setBlockId]);

    useLayoutEffect(() => {
      if (currentExercise && exerciseId !== currentExercise.id && blockId) {
        setExerciseId(currentExercise.id);
      }
    }, [blockId, currentExercise, exerciseId, setExerciseId]);

    useLayoutEffect(() =>
      autorun(() => {
        if (currentExercise) {
          const answer = ExerciseAnswerModel.create({
            id: currentExercise.id,
          });

          exerciseAnswerStore.addItem(answer, currentExercise.isSpeaking);
          currentExercise.setAnswer(answer.id);
        }
      })
    );

    const handleCourseResultClose = () => setIsCourseResultOpen(false);
    const handleUnitResultClose = () => setIsUnitResultOpen(false);
    const handleTestUnitResultClose = () => setIsTestUnitResultOpen(false);
    const handleBlockResultClose = () => setIsBlockResultOpen(false);

    const handleUnitSelect = useCallback(
      (newUnitId: string) => () =>
        navigate(getUnitUrl(courseId as string, newUnitId)),
      [courseId, navigate]
    );

    const handleBlockSelect = useCallback(
      (newBlockId: string) => () => setBlockId(newBlockId),
      [setBlockId]
    );

    const handleExerciseSelect = useCallback(
      (newExerciseId: string) => () => {
        setExerciseId(newExerciseId);
      },
      [setExerciseId]
    );

    const handleCourseResultConfirm = () => {
      navigate(getCourseUrl(courseId as string));
    };

    const handleUnitResultConfirm = useCallback(() => {
      if (!currentCourse || !currentUnit) {
        return;
      }

      handleUnitResultClose();

      const nextUnit = currentCourse.getNextUnit(currentUnit.id);

      if (nextUnit) {
        navigate(getUnitUrl(courseId as string, nextUnit.id));
        return;
      }

      navigate(getCourseUrl(courseId as string));
    }, [courseId, currentCourse, currentUnit, navigate]);

    const handleTestUnitResultConfirm = useCallback(() => {
      if (!currentCourse || !currentUnit || !courseId) {
        return;
      }

      const nextUnit = currentCourse?.getNextUnit(currentUnit.id);

      handleTestUnitResultClose();

      if (nextUnit) {
        navigate(getUnitUrl(courseId, nextUnit.id));
        return;
      }

      setIsCourseResultOpen(true);
    }, [courseId, currentCourse, currentUnit, navigate]);

    const handleNextUnit = useCallback(() => {
      if (!currentUnit) return;

      const nextUnit = currentCourse?.getNextUnit(currentUnit.id);

      if (!currentCourse?.isForTesting) {
        if (auth.dontShowExercisesResults || !showExercisesResults) {
          handleUnitResultConfirm();
          return;
        }

        setIsUnitResultOpen(true);
        return;
      }

      // Тестовые курсы прерываются по этому условнию, например тест по определеню уровня владения английским
      if (!currentUnit?.passing?.successful) {
        return setIsCourseResultOpen(true);
      }

      if (nextUnit) {
        setIsTestUnitResultOpen(true);
        return;
      }

      setIsCourseResultOpen(true);
    }, [
      auth.dontShowExercisesResults,
      currentCourse,
      currentUnit,
      handleUnitResultConfirm,
      showExercisesResults,
    ]);

    const handleBlockResultConfirm = useCallback(() => {
      if (!currentUnit) return;

      const { blocks } = currentUnit;

      const blockIndex = blocks.findIndex(({ id }) => id === blockId);

      if (blockIndex !== blocks.length - 1) {
        const { id: newBlockId } = blocks[blockIndex + 1];
        setBlockId(newBlockId);
        return;
      }

      handleNextUnit();
    }, [currentUnit, handleNextUnit, blockId, setBlockId]);

    const handleNextBlock = useCallback(() => {
      if (
        currentCourse?.isForTesting ||
        auth.dontShowExercisesResults ||
        !showExercisesResults
      ) {
        handleBlockResultConfirm();
        return;
      }

      setIsBlockResultOpen(true);
    }, [
      auth.dontShowExercisesResults,
      currentCourse?.isForTesting,
      handleBlockResultConfirm,
      showExercisesResults,
    ]);

    const handleNextExercise = useCallback(() => {
      if (!currentCourse || !currentBlock || !currentExercise) {
        return;
      }

      // Этот тип упражнения должен проверяться по кнопке вперёд
      if (currentExercise?.isConfirmation) {
        currentExercise?.check(currentCourse?.passing?.id as string);
      }

      const { exercises } = currentBlock;

      const exerciseIndex = exercises.findIndex(({ id }) => id === exerciseId);

      if (exerciseIndex !== exercises.length - 1) {
        const { id: newExerciseId } = exercises[exerciseIndex + 1];
        setExerciseId(newExerciseId);

        return;
      }

      handleNextBlock();
    }, [
      currentCourse,
      currentBlock,
      currentExercise,
      exerciseId,
      setExerciseId,
      handleNextBlock,
    ]);

    const handlePrevExercise = useCallback(() => {
      if (!currentCourse || !currentUnit || !currentBlock || !currentExercise) {
        return;
      }

      const { sortedUnits: units } = currentCourse;
      const { blocks } = currentUnit;
      const { exercises } = currentBlock;

      const exerciseIndex = exercises.findIndex(({ id }) => id === exerciseId);

      if (exerciseIndex !== 0) {
        const { id: newExerciseId } = exercises[exerciseIndex - 1];
        setExerciseId(newExerciseId);

        return;
      }

      const blockIndex = blocks.findIndex(({ id }) => id === blockId);

      if (blockIndex !== 0) {
        const { id: newBlockId } = blocks[blockIndex - 1];
        setBlockId(newBlockId);

        return;
      }

      const unitIndex = units.findIndex(({ id }) => id === unitId);

      if (currentCourse?.lastUnit.id !== currentUnit.id) {
        const { id: newUnitId } = units[unitIndex - 1];
        handleUnitSelect(newUnitId);
      }
    }, [
      currentCourse,
      currentUnit,
      currentBlock,
      currentExercise,
      exerciseId,
      setExerciseId,
      blockId,
      setBlockId,
      unitId,
      handleUnitSelect,
    ]);

    const handleCheck = useCallback(() => {
      if (currentExercise?.isDialog) {
        return handleNextExercise();
      }

      return currentExercise?.check(currentCourse?.passing?.id as string);
    }, [currentCourse?.passing?.id, currentExercise, handleNextExercise]);

    const pageLoading =
      rootStore.loading ||
      unitStore.loading ||
      !currentCourse ||
      loadingCourses ||
      loadingPassings;

    const contentLoading = blockStore.loading;

    return (
      <ThemeProvider theme={defaultTheme}>
        <I18nextProvider i18n={i18n}>
          <DndProvider
            backend={isTouchDevice() ? TouchBackend : HTML5Backend}
            {...(isTouchDevice() && {
              options: {
                enableMouseEvents: true,
              },
            })}
          >
            <DragDropProvider>
              <TranslateModeProvider>
                <Loading loading={pageLoading}>
                  <ContentContainer
                    indented
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      gap: 4,
                    }}
                  >
                    <NavigationPanel
                      currentCourse={currentCourse}
                      currentUnit={currentUnit}
                      currentBlock={currentBlock}
                      currentExercise={currentExercise}
                      onUnitChange={handleUnitSelect}
                      onBlockChange={handleBlockSelect}
                      onExerciseChange={handleExerciseSelect}
                    />

                    <Loading loading={contentLoading}>
                      {currentExercise?.broken ? (
                        <BrokenExercise
                          linkToOldInterface={linkToOldInterface}
                        />
                      ) : (
                        <ContentView
                          course={currentCourse}
                          unit={currentUnit}
                          block={currentBlock}
                          exercise={currentExercise}
                          onNextExercise={handleNextExercise}
                          onPrevExercise={handlePrevExercise}
                          onCheck={handleCheck}
                          showAdditionalContentWhenEmpty={
                            showAdditionalContentWhenEmpty
                          }
                        />
                      )}
                    </Loading>

                    <TranslationMenu />

                    {isMobile && <TranslationButton />}
                  </ContentContainer>
                </Loading>
              </TranslateModeProvider>
            </DragDropProvider>
            <ModalTestResult
              isOpen={isCourseResultOpen}
              onConfirm={handleCourseResultConfirm}
              onClose={handleCourseResultClose}
              course={currentCourse ?? null}
            />
            {currentBlock && (
              <ModalBlockResult
                isOpen={isBlockResultOpen}
                onClose={handleBlockResultClose}
                onConfirm={handleBlockResultConfirm}
                block={currentBlock}
                onExerciseChange={handleExerciseSelect}
              />
            )}
            {currentCourse && currentUnit && (
              <>
                <ModalUnitResult
                  isOpen={isUnitResultOpen}
                  onClose={handleUnitResultClose}
                  onConfirm={handleUnitResultConfirm}
                  unit={currentUnit}
                  onBlockChange={handleBlockSelect}
                />
                <ModalTestUnitResult
                  isOpen={isTestUnitResultOpen}
                  onConfirm={handleTestUnitResultConfirm}
                  onClose={handleTestUnitResultClose}
                  unit={currentUnit}
                />
              </>
            )}
          </DndProvider>
        </I18nextProvider>

        <CssBaseline />
      </ThemeProvider>
    );
  }
);
