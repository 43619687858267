import { ComponentProps } from "react";

import { Box, CircularProgress, SxProps, Theme } from "@mui/material";

import { LoaderWrapper } from "./styledComponents/LoaderWrapper";

export interface LoadingProps {
  loading: boolean;
  children: JSX.Element[] | JSX.Element | null;
  cover?: boolean;
  wrapperStyles?: SxProps<Theme>;
  size?: ComponentProps<typeof CircularProgress>["size"];
}

export const Loading = ({
  loading,
  children,
  cover,
  wrapperStyles,
  size,
}: LoadingProps): JSX.Element | null => {
  if (cover) {
    return (
      <Box sx={{ position: "relative" }}>
        {loading && (
          <LoaderWrapper cover sx={wrapperStyles}>
            <CircularProgress size={size} />
          </LoaderWrapper>
        )}

        {children}
      </Box>
    );
  }

  return (
    <>
      {loading ? (
        <LoaderWrapper sx={wrapperStyles}>
          <CircularProgress size={size} />
        </LoaderWrapper>
      ) : (
        children
      )}
    </>
  );
};
