const ru = {
  Classes: {
    SampleByStatus: "Выборка по статусу занятий за период",
    OnlyLessonsWithoutGroup: "Только занятия без групп",
    Title: "Занятия",
    Company: "Компания",
    Group: "Группа",
    Status: "Статус",
    ClassIndex: "Занятие",
    ClassDate: "Дата занятия",
    Teacher: "Преподаватель",
    None: "Ничего не выбрано",
    Process: "Обработать",
    DateRangeError:
      "Для обработки нужно выбрать дату начала и дату завершения.",
    NoDataError: "Нет данных для обработки.",
    StandardHelper:
      "Чтобы отобразить данные, воспользуйтесь хотя бы одним фильтром.",
    ProcessHelper: "Чтобы отобразить данные, выберите дату.",
    Processing: "Данные обрабатываются",
    Type: "Тип",
    Unit: "Раздел",
    Duration: "Ак.ч",
  },
  ClassesDownload: {
    Download: "Выгрузить",
    Classes: "Занятия",
  },
  GroupsAutocompleteFilter: {
    EnterGroupName: "Введите название группы",
    SelectGroup: "Выбор группы",
  },
  GroupsEmptyTable: {
    FirstTextDefault: "Вам не назначены учебные модули.",
    SecondTextDefault: "Обратитесь к вашему координатору обучения.",
    FirstTextDistributor: "Нет данных для отображения.",
    SecondTextDistributor: "Попробуйте внести какие нибудь данные.",
    FirstTextClasses: "Нет данных для отображения.",
    SecondTextClasses:
      "Попробуйте поменять фильтры или обратитесь к поддержке.",
  },
  TeachersAutocompleteFilter: {
    EnterTeacherName: "Учителя не найдены",
    SelectTeacher: "Выбор учителя",
  },
  Loading: {
    Error: "Ошибка загрузки, попробуйте обновить страницу",
  },
  DateRangePicker: {
    StartDate: "Дата начала",
    EndDate: "Дата завершения",
  },
  DatePicker: {
    Today: "Сегодня",
    Clear: "Очистить",
    MaskPlaceholder: "ДД/ММ/ГГГГ",
  },
  LessonStatuses: {
    planned: "Запланировано",
    completed: "Проведено",
    studentLateCancellation: "Поздняя отмена слушателями",
    earlyCanceledByCustomer: "Заблаговременная отмена слушателями",
    earlyCanceledBySchool: "ДС Заблаговременная отмена",
    lateCanceledByCustomer: "Поздняя отмена слушателями",
    lateCanceledBySchool: "ДС Поздняя отмена",
    notPerformedToPay: "К оплате",
  },
};

export default ru;
