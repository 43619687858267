import { Navigate, RouteObject } from "react-router-dom";

import { JetRedirect } from "components/JetRedirect";
import { ModalUnit } from "components/ModalUnit";
import { AllPayrollGroups } from "pages/AllPayrollGroups";
import { CancellationReport } from "pages/CancellationReport";
import { Classes } from "pages/Classes";
import { Companies } from "pages/Companies";
import { CreativeTasks } from "pages/CreativeTasks";
import { Distributor } from "pages/Distributor";
import { MainDistributor } from "pages/Distributor/MainDistributor";
import { EmailNotConfirmed } from "pages/EmailNotConfirmed";
import { Exercise } from "pages/Exercise";
import { Groups } from "pages/Groups";
import { GroupReport } from "pages/Groups/Report";
import { HR } from "pages/HR";
import { HRMain } from "pages/HR/HRMain";
import { Dashboard } from "pages/Layouts/Dashboard";
import { Unauthorized } from "pages/Layouts/Unauthorized";
import { Login, RestorePassword, SetupPassword } from "pages/Login";
import { PayrollAccountant } from "pages/PayrollAccountant";
import { MainPayrollAccountant } from "pages/PayrollAccountant/MainPayrollAccountant";
import { Profile } from "pages/Profile";
import { PromocodeExpired } from "pages/PromocodeExpired";
import { Register } from "pages/Register";
import { Reports, AttendanceReports } from "pages/Reports";
import {
  CreateStudentReport,
  CreateTeacherReport,
} from "pages/Reports/CreateReport";
import { DetailReportRedirect } from "pages/Reports/DetailReport";
import { Student } from "pages/Student";
import { MainStudents } from "pages/Student/MainStudents";
import { MainModule } from "pages/Student/Module/MainModule";
import { MyModules } from "pages/Student/MyModules";
import { MyWords } from "pages/Student/MyWords";
import { Summary } from "pages/Summary";
import { TaggingList } from "pages/TaggingList";
import { Teacher } from "pages/Teacher";
import { LessonAttendance } from "pages/Teacher/Attendance";
import { MainTeacher } from "pages/Teacher/MainTeacher";
import { TeacherDetails } from "pages/TeacherDetails";
import { Teachers } from "pages/Teachers";

import { ROUTES } from "./constants";
import { UsefulLinks } from "../pages/UsefulLinks";

export const unauthorizedRoutes: RouteObject = {
  path: ROUTES.SIGN_IN_PAGE,
  element: <Unauthorized />,
  children: [
    {
      path: ROUTES.SIGN_IN_PAGE,
      element: <Login />,
    },
    {
      path: ROUTES.SIGN_UP_PAGE,
      element: <Register />,
    },
    {
      path: ROUTES.RESTORE_PASSWORD,
      element: <RestorePassword />,
    },
    {
      path: ROUTES.PROMOCODE_EXPIRED,
      element: <PromocodeExpired />,
    },
    {
      path: "*",
      element: <Navigate to={ROUTES.SIGN_IN_PAGE} />,
    },
  ],
};

export const passwordSetupRouters: RouteObject = {
  path: ROUTES.SETUP_PASSWORD,
  element: <Unauthorized />,
  children: [
    {
      path: ROUTES.SETUP_PASSWORD,
      element: <SetupPassword />,
    },
  ],
};

export const emailNotConfirmedRoutes: RouteObject = {
  path: ROUTES.EMAIL_NOT_CONFIRMED,
  element: <Unauthorized />,
  children: [
    {
      path: ROUTES.EMAIL_NOT_CONFIRMED,
      element: <EmailNotConfirmed />,
    },
    {
      path: "*",
      element: <Navigate to={ROUTES.EMAIL_NOT_CONFIRMED} />,
    },
  ],
};

export const studentRoutes: RouteObject = {
  path: ROUTES.HOME,
  element: <Dashboard />,
  children: [
    {
      path: ROUTES.HOME,
      element: <Student />,
      children: [
        { path: ROUTES.HOME, element: <MainStudents /> },
        {
          path: ROUTES.MY_COURSES,
          element: <MyModules />,
        },
        {
          path: `${ROUTES.MY_COURSES}/:id`,
          element: <MainModule />,
          children: [
            {
              path: `${ROUTES.MY_COURSES}/:id${ROUTES.MODAL_UNIT}/:unitId`,
              element: <ModalUnit />,
            },
          ],
        },
        {
          path: `${ROUTES.MY_COURSES}/:id${ROUTES.EXERCISES}/:unitId`,
          element: <Exercise />,
        },
        {
          path: ROUTES.MY_WORDS,
          element: <MyWords />,
        },
        {
          path: ROUTES.ATTENDANCE_REPORT,
          element: <AttendanceReports />,
        },
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
      ],
    },
    {
      path: "/my-modules",
      element: <JetRedirect url={ROUTES.MY_COURSES} />,
    },
    {
      path: ROUTES.CREATIVE_TASKS,
      element: <CreativeTasks />,
    },
    {
      path: "/additional-modules",
      element: <JetRedirect url="/learn/courses/additional" />,
    },
    {
      path: "/useful-links",
      element: <UsefulLinks />,
    },
    {
      path: "*",
      element: <Navigate to={ROUTES.HOME} />,
    },
  ],
};

export const teacherRoutes: RouteObject = {
  path: ROUTES.HOME,
  element: <Dashboard />,
  children: [
    {
      path: ROUTES.HOME,
      element: <Teacher />,
      children: [
        {
          path: ROUTES.HOME,
          element: <MainTeacher />,
          children: [
            { path: `${ROUTES.ATTENDANCE}/:id`, element: <LessonAttendance /> },
          ],
        },
        {
          path: ROUTES.MY_COURSES,
          element: <MyModules />,
        },
        {
          path: `${ROUTES.MY_COURSES}/:id`,
          element: <MainModule />,
          children: [
            {
              path: `${ROUTES.MY_COURSES}/:id${ROUTES.MODAL_UNIT}/:unitId`,
              element: <ModalUnit />,
            },
          ],
        },
        {
          path: `${ROUTES.MY_COURSES}/:id${ROUTES.EXERCISES}/:unitId`,
          element: <Exercise />,
        },
        {
          path: ROUTES.GROUPS_PAGE,
          element: <Groups />,
          children: [
            {
              path: `${ROUTES.GROUPS_PAGE}/:id`,
              element: <GroupReport />,
            },
          ],
        },
        {
          path: ROUTES.CLASSES,
          element: <Classes />,
          children: [
            {
              path: `${ROUTES.CLASSES}${ROUTES.MODAL_GROUP}/:id`,
              element: <GroupReport />,
            },
            {
              path: `${ROUTES.CLASSES}${ROUTES.ATTENDANCE}/:id`,
              element: <LessonAttendance />,
            },
          ],
        },
        {
          path: ROUTES.REPORTS,
          element: <JetRedirect url={ROUTES.STUDENTS_REPORTS} />,
        },
        {
          path: ROUTES.STUDENTS_REPORTS,
          element: <Reports />,
          children: [
            {
              path: `${ROUTES.STUDENTS_REPORTS}/create`,
              element: <CreateStudentReport />,
            },
          ],
        },
        {
          path: ROUTES.TEACHERS_REPORTS,
          element: <Reports />,
          children: [
            {
              path: `${ROUTES.TEACHERS_REPORTS}/create`,
              element: <CreateTeacherReport />,
            },
          ],
        },
        {
          path: `${ROUTES.STUDENTS_REPORTS}/detail/:id`,
          element: <DetailReportRedirect />,
        },
        {
          path: `${ROUTES.TEACHERS_REPORTS}/detail/:id`,
          element: <DetailReportRedirect />,
        },
        {
          path: ROUTES.ENTRANCE_REPORTS,
          element: <JetRedirect url="/reports/entrance_testings_report" />,
        },
        {
          path: ROUTES.LICENSE_REPORTS,
          element: <JetRedirect url="/reports/license_consumption_report" />,
        },
        {
          path: ROUTES.ATTENDANCE_REPORT,
          element: <AttendanceReports />,
        },
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
      ],
    },
    {
      path: ROUTES.MY_WORDS,
      element: <MyWords />,
    },
    {
      path: ROUTES.CREATIVE_TASKS,
      element: <CreativeTasks />,
    },
    {
      path: "/additional-modules",
      element: <JetRedirect url="/learn/courses/additional" />,
    },
    {
      path: "/useful-links",
      element: <UsefulLinks />,
    },
  ],
};

export const hrRoutes: RouteObject = {
  path: ROUTES.HOME,
  element: <Dashboard />,
  children: [
    {
      path: ROUTES.HOME,
      element: <HR />,
      children: [
        { path: ROUTES.HOME, element: <HRMain /> },
        {
          path: ROUTES.MY_COURSES,
          element: <MyModules />,
        },
        {
          path: `${ROUTES.MY_COURSES}/:id`,
          element: <MainModule />,
          children: [
            {
              path: `${ROUTES.MY_COURSES}/:id${ROUTES.MODAL_UNIT}/:unitId`,
              element: <ModalUnit />,
            },
          ],
        },
        {
          path: ROUTES.GROUPS_PAGE,
          element: <Groups />,
          children: [
            {
              path: `${ROUTES.GROUPS_PAGE}/:id`,
              element: <GroupReport />,
            },
          ],
        },
        {
          path: ROUTES.REPORTS,
          element: <JetRedirect url={ROUTES.STUDENTS_REPORTS} />,
        },
        {
          path: ROUTES.STUDENTS_REPORTS,
          element: <Reports />,
          children: [
            {
              path: `${ROUTES.STUDENTS_REPORTS}/create`,
              element: <CreateStudentReport />,
            },
          ],
        },
        {
          path: ROUTES.TEACHERS_REPORTS,
          element: <Reports />,
          children: [
            {
              path: `${ROUTES.TEACHERS_REPORTS}/create`,
              element: <CreateTeacherReport />,
            },
          ],
        },
        {
          path: `${ROUTES.STUDENTS_REPORTS}/detail/:id`,
          element: <DetailReportRedirect />,
        },
        {
          path: `${ROUTES.TEACHERS_REPORTS}/detail/:id`,
          element: <DetailReportRedirect />,
        },
        {
          path: ROUTES.ENTRANCE_REPORTS,
          element: <JetRedirect url="/reports/entrance_testings_report" />,
        },
        {
          path: ROUTES.LICENSE_REPORTS,
          element: <JetRedirect url="/reports/license_consumption_report" />,
        },
        {
          path: ROUTES.ATTENDANCE_REPORT,
          element: <AttendanceReports />,
        },
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
      ],
    },
    {
      path: ROUTES.MY_WORDS,
      element: <MyWords />,
    },
    {
      path: ROUTES.CREATIVE_TASKS,
      element: <CreativeTasks />,
    },
    {
      path: "/additional-modules",
      element: <JetRedirect url="/learn/courses/additional" />,
    },
    {
      path: "/useful-links",
      element: <UsefulLinks />,
    },
    {
      path: "/reports-design",
      element: <Reports />,
    },
  ],
};

export const distributorRoutes: RouteObject = {
  path: ROUTES.HOME,
  element: <Dashboard />,
  children: [
    {
      path: ROUTES.HOME,
      element: <Distributor />,
      children: [
        { path: ROUTES.HOME, element: <MainDistributor /> },
        {
          path: ROUTES.MY_COURSES,
          element: <MyModules />,
        },
        {
          path: `${ROUTES.MY_COURSES}/:id`,
          element: <MainModule />,
          children: [
            {
              path: `${ROUTES.MY_COURSES}/:id${ROUTES.MODAL_UNIT}/:unitId`,
              element: <ModalUnit />,
            },
          ],
        },
        {
          path: `${ROUTES.MY_COURSES}/:id${ROUTES.EXERCISES}/:unitId`,
          element: <Exercise />,
        },
        {
          path: ROUTES.GROUPS_PAGE,
          element: <Groups />,
          children: [
            {
              path: `${ROUTES.GROUPS_PAGE}/:id`,
              element: <GroupReport />,
            },
          ],
        },
        {
          path: ROUTES.CLASSES,
          element: <Classes />,
          children: [
            {
              path: `${ROUTES.CLASSES}${ROUTES.MODAL_GROUP}/:id`,
              element: <GroupReport />,
            },
            {
              path: `${ROUTES.CLASSES}${ROUTES.ATTENDANCE}/:id`,
              element: <LessonAttendance />,
            },
          ],
        },
        {
          path: ROUTES.REPORTS,
          element: <JetRedirect url={ROUTES.STUDENTS_REPORTS} />,
        },
        {
          path: ROUTES.STUDENTS_REPORTS,
          element: <Reports />,
          children: [
            {
              path: `${ROUTES.STUDENTS_REPORTS}/create`,
              element: <CreateStudentReport />,
            },
          ],
        },
        {
          path: ROUTES.TEACHERS_REPORTS,
          element: <Reports />,
          children: [
            {
              path: `${ROUTES.TEACHERS_REPORTS}/create`,
              element: <CreateTeacherReport />,
            },
          ],
        },
        {
          path: `${ROUTES.STUDENTS_REPORTS}/detail/:id`,
          element: <DetailReportRedirect />,
        },
        {
          path: `${ROUTES.TEACHERS_REPORTS}/detail/:id`,
          element: <DetailReportRedirect />,
        },
        {
          path: ROUTES.ENTRANCE_REPORTS,
          element: <JetRedirect url="/reports/entrance_testings_report" />,
        },
        {
          path: ROUTES.LICENSE_REPORTS,
          element: <JetRedirect url="/reports/license_consumption_report" />,
        },
        {
          path: ROUTES.ATTENDANCE_REPORT,
          element: <AttendanceReports />,
        },
        {
          path: ROUTES.PROFILE,
          element: <Profile />,
        },
        {
          path: ROUTES.COMPANIES,
          element: <Companies />,
        },
      ],
    },
    {
      path: ROUTES.MY_WORDS,
      element: <MyWords />,
    },
    {
      path: ROUTES.CREATIVE_TASKS,
      element: <CreativeTasks />,
    },
    {
      path: "/additional-modules",
      element: <JetRedirect url="/learn/courses/additional" />,
    },
    {
      path: "/useful-links",
      element: <UsefulLinks />,
    },
    {
      path: ROUTES.ATTENDANCE_REPORT,
      element: <AttendanceReports />,
    },
    {
      path: ROUTES.SUMMARY,
      element: <Summary />,
    },
    {
      path: ROUTES.CANCELLATION_REPORT,
      element: <CancellationReport />,
    },
  ],
};

export const payrollAccountantRoutes: RouteObject = {
  path: ROUTES.HOME,
  element: <Dashboard />,
  children: [
    {
      path: ROUTES.HOME,
      element: <PayrollAccountant />,
      children: [
        { path: ROUTES.HOME, element: <MainPayrollAccountant /> },
        { path: ROUTES.ALL_PAYROLL_GROUPS, element: <AllPayrollGroups /> },
        {
          path: ROUTES.TEACHERS,
          element: <Teachers />,
        },
        {
          path: `${ROUTES.TEACHERS}/:id`,
          element: <TeacherDetails />,
        },
        {
          path: ROUTES.GROUPS_PAGE,
          element: <Groups />,
          children: [
            {
              path: `${ROUTES.GROUPS_PAGE}/:id`,
              element: <GroupReport />,
            },
          ],
        },
        {
          path: ROUTES.CLASSES,
          element: <Classes />,
          children: [
            {
              path: `${ROUTES.CLASSES}${ROUTES.MODAL_GROUP}/:id`,
              element: <GroupReport />,
            },
            {
              path: `${ROUTES.CLASSES}${ROUTES.ATTENDANCE}/:id`,
              element: <LessonAttendance />,
            },
          ],
        },
        {
          path: `${ROUTES.TAGGING_LIST}/:id`,
          element: <TaggingList />,
        },
        {
          path: ROUTES.ATTENDANCE_REPORT,
          element: <AttendanceReports />,
        },
      ],
    },
    {
      path: ROUTES.SUMMARY,
      element: <Summary />,
    },
  ],
};
