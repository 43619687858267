import { Typography } from "@mui/material";

import { FlexList } from "../../components/FlexList";

const formatTranslation = (translation: string) => {
  return translation.split(", ").slice(0, 3).join(", ");
};

interface TranslationProps {
  translations: string[];
}

export const Translation = ({
  translations,
}: TranslationProps): JSX.Element | null => {
  if (translations.length === 1) {
    return (
      <Typography variant="regularText">
        {formatTranslation(translations[0])}
      </Typography>
    );
  }

  return (
    <FlexList
      gap={0}
      items={translations}
      renderItem={(translation, index) => (
        <Typography key={index} variant="regularText">
          {`${index + 1}. ${formatTranslation(translation)}`}
        </Typography>
      )}
    />
  );
};
