const ur = {
  CreativeTasks: {
    Title: "تخلیقی کام",
    UpdateTaskTeacher: "محفوظ کریں",
    SubmitTaskTeacher: "نتائج طالب علم کو بھیجیں",
    UpdateTaskStudent: "مسودہ محفوظ کریں",
    SubmitTaskStudent: "محفوظ کریں اور جائزے کے لیے بھیجیں",
    AnswerHeaderStatistics:
      "سوالات: {{questions}} | علامات: {{symbols}} | الفاظ: {{words}} | جملے: {{sentenses}}",
    QuestionsAndComments: "غلطیاں اور تبصرے:",
    HoverForDisplay: "دکھانے کے لیے ہوور کریں:",
    CheckingHistory: "چیکنگ کی تاریخ",
    CurrentVersion: "موجودہ ورژن",
    TeacherComment: "استاد کی جانب سے کام پر تبصرہ:",
    CommentPlaceholder: "آپ یہاں کام پر تبصرہ چھوڑ سکتے ہیں۔",
    SaveCorrection: "محفوظ کریں",
    CancelCorrection: "منسوخ کریں",
    Grammar: "گرامر",
    Lexical: "لغت",
    Comment: "تبصرہ",
    ErrorRangeFilled: "آپ نے منتخب کردہ حد میں تمام غلطیوں کی اقسام بھر دی ہیں",
    SaveError: "محفوظ کریں",
    GrammarErrorTitle: "گرامر",
    ResultTitle: "تخلیقی کام کے نتائج",
    Student: "طالب علم",
    Teacher: "استاد",
    CurrentVersionTime: "موجودہ ورژن: {{time}}",
    TaskText: "کام",
    HasErrors: "غلطیاں موجود ہیں",
    WithoutErrors: "غلطیوں کے بغیر مکمل ہوا",
    UpdateSavedTeacher: "آپ کی تبدیلیاں محفوظ کر لی گئی ہیں",
    UpdateSavedStudent: "آپ کا مسودہ محفوظ کر لیا گیا ہے",
    UpdateSubmitedTeacher: "جائزے کے نتائج بھیجے گئے ہیں۔",
    UpdateSubmitedStudent: "آپ کی ترمیمات بھیجی گئی ہیں۔",
    Checking: "چیکنگ کے تحت",
    Done: "مکمل",
    FixStudent: "درست کریں",
    FixTeacher: "غلطیاں موجود ہیں",
    CheckingTime: "چیکنگ • {{time}}",
    DoneTime: "مکمل • {{time}}",
    TeacherName: "استاد: {{name}}",
    StudentName: "طالب علم: {{name}}",
    AllStatuses: "تمام حیثیتیں",
    Checked: "چیک کیا گیا",
    NewCheck: "چیکنگ کے تحت",
    Processing: "پروسیسنگ ہو رہی ہے",
    StudentSearch: "طالب علم کی تلاش",
    TeacherSearch: "استاد کی تلاش",
    EmptyList: "اس وقت کوئی موزوں کام موجود نہیں ہے",
    TaskSubtitle: "باب {{unit}} • {{part}} • ورزش {{exercise}}",
  },
};

export default ur;
