import { useQuery, useMutation } from "@tanstack/react-query";

import { synthesizeSpeech, translateText, getWords } from "./index";

export const useGetWords = (unitId: string, disabled = false) => {
  return useQuery({
    queryKey: ["words", unitId],
    queryFn: () => getWords(unitId),
    enabled: !disabled,
  });
};

export const useSynthesizeSpeech = () => {
  return useMutation({
    mutationFn: synthesizeSpeech,
  });
};

export const useTranslateText = () => {
  return useMutation({
    mutationFn: translateText,
  });
};
