const ur = {
  Exercise: {
    Units: "یونٹس",
    Blocks: "بلاکس",
    Exercises: "مشقیں",
    Unit: "یونٹ",
    Block: "بلاک",
    Exercise: "مشق",
    Script: "ویڈیو اسکرپٹ",
    HideScript: "اسکرپٹ چھپائیں",
    ShowScript: "اسکرپٹ دکھائیں",
    LanguageSamples: "زبان کے نمونے",
    Extras: "اضافی مواد",
    ToolReload: "دوبارہ لوڈ کریں",
    ToolTranslate: "ترجمہ کریں",
    TranslateHelper: "ترجمہ اور لغت کے کام کے لیے مشق کے اندر متن منتخب کریں۔",
    ToolCheck: "چیک کریں",
    ToolNext: "اگلا",
    IsCheckedWarning: "کام پہلے ہی چیک کیا جا چکا ہے",
    NotFilledWarning: "آپ نے تمام سوالات کا جواب نہیں دیا",
    Translate: "ترجمہ",
    Vocabulary: "لغت",
    NoTranslation: "ترجمہ موجود نہیں",
    AddToDictionary: "لغت میں شامل کریں",
    SelectedTranslationsWarning: "آپ کو 1-3 ترجمے منتخب کرنے ہوں گے",
    TranslateWordFailed: "لفظ کا ترجمہ ناکام ہو گیا",
    AddWordsFailed: "الفاظ شامل کرنے میں ناکام",
    AddWordsSuccess: "الفاظ کامیابی کے ساتھ شامل ہو گئے",
    AddedBefore: "پہلے ہی شامل ہو چکا ہے",
    EnterText: "یہاں لکھیں",
    Characters: "حروف",
    Words: "الفاظ",
    TextSize: "آپ کے متن کا حجم:",
    WordsAtMinimum: "کم سے کم الفاظ",
    LearnWords: "الفاظ سیکھیں",
    Ok: "ٹھیک ہے",
    Expand: "پھیلائیں",
    Collapse: "سمیٹیں",
    Close: "بند کریں",
    ResizeTooltip: "پڑھنے کے لیے پھیلائیں",
    ResetChangesTooltip: "تمام تبدیلیوں کو ری سیٹ کریں",
    NoChange: "کوئی تبدیلی نہیں",
    CorrectAnswer: "درست جواب",
    DevTooltip:
      "یہ قسم کی مشق ابھی تیار ہو رہی ہے، پرانے انٹرفیس میں جانے کے لیے",
    FollowLink: "اس لنک کو فالو کریں",
    CreativeExercisePassed: "آپ کا کام آپ کے استاد کو بھیج دیا گیا ہے",
    CreativeExerciseVerified: "آپ کا کام چیک ہو چکا ہے۔ کلک کریں",
    CreativeExerciseVerifiedLink: "یہاں",
    DialogExerciseConfirmAnswer: "تصدیق کریں",
    DialogExerciseResetAnswer: "دوبارہ ریکارڈ کریں",
    DialogExerciseSelectAnswer: "یہ",
    DialogExerciseHelperLegend: "مشق کرنے کا طریقہ",
    DialogExerciseFinishHelperLegend: "مبارک ہو!",
    DialogExerciseFirstHelper:
      "ذہنی طور پر نیچے دیے گئے پیغامات کی فہرست سے اپنے جواب کا انتخاب کریں، مائیکروفون پر کلک کریں اور اسے بولیں۔",
    DialogExerciseSecondHelper:
      "زبردست، نظام نے آپ کی بات کو پہچان لیا ہے! براہ کرم اپنے جواب کی تصدیق کریں یا دوبارہ ریکارڈ کریں۔",
    DialogExerciseThirdHelper:
      "آپ کی آواز کی ان پٹ کی کوششیں ختم ہو چکی ہیں، جواب کے اختیارات میں سے ایک کا دستی انتخاب کریں۔",
    DialogExerciseFinishHelper:
      "آپ نے مشق مکمل کر لی ہے۔ آپ پورے مکالمے کو سن سکتے ہیں یا چیک بٹن پر کلک کر کے اگلی مشق پر جا سکتے ہیں۔",
    DialogExerciseRecognitionError:
      "بدقسمتی سے، نظام آپ کے جواب کو پہچان نہیں سکا، براہ کرم دوبارہ کوشش کریں۔",
    AudioRecorderText: "ریکارڈ کرنے کے لیے کلک کریں ({{count}}) سیکنڈ",
    RadioLineHelperTextLeft: "تمام درست جوابات نہیں دیے گئے۔ تلاش کریں",
    RadioLineHelperTextRight: "مزید درست جوابات۔",
    NextDisabledWarning: "مشق چیک کریں",
    BrokenError:
      "یہ مشق ابھی تیار ہو رہی ہے، براہ کرم اسے پرانے انٹرفیس میں مکمل کریں۔",
    BrokenErrorLink: "لنک",
  },
  ResultsModals: {
    UnitTitle: "یونٹ کے نتائج {{name}}",
    BlockTitle: "بلاک کے نتائج {{name}}",
    Passed: "پاس ہوئے: {{count}}",
    Wrong: "غلط: {{count}}",
    Skipped: "چھوڑے گئے: {{count}}",
    Blocks: "بلاکس",
    Block: "بلاک {{order}}: {{name}}",
    Exercises: "مشقیں",
    Exercise: "مشق {{order}}: {{name}}",
    Repeat: "دوبارہ کریں",
    Continue: "جاری رکھیں",
    DontShowAgain:
      "یہ ونڈو دوبارہ نہ دکھائیں (آپ پروفائل کی ترتیبات میں اسے دوبارہ فعال کر سکتے ہیں)",
    TestUnitTitle: "مبارک ہو! آپ نے {{order}} کا امتحانی مرحلہ مکمل کر لیا ہے",
    TestUnitResultTitle: "آپ کا نتیجہ ہے",
    TestUnit: "یونٹ {{order}}. {{name}}",
    TestUnitTotalProgress: "مجموعی پیش رفت",
    TestUnitResults: "نتائج",
    TestUnitNext: "اگلے مرحلے کا آغاز کریں",
    TestUnitScoredPoints_zero: "{{count}}/{{total}} پوائنٹس",
    TestUnitScoredPoints_one: "{{count}}/{{total}} پوائنٹ",
    TestUnitScoredPoints_other: "{{count}}/{{total}} پوائنٹس",
  },
  TestingCourseResult: {
    ModalTitle: "ٹیسٹ کے نتائج",
    Confirm: "نتائج پر جائیں",
    ModalBodyEntrance:
      "محترم {{fullName}}، آپ کو CEFR سطح کا کورس کرنے کی سفارش کی جاتی ہے: {{cefrLevel}}.",
    ModalBodyFinal:
      "محترم {{fullName}}، آپ نے {{maxMark}} میں سے {{courseMark}} پوائنٹس کے ساتھ ٹیسٹ مکمل کیا ہے۔\nآپ کا اسکور {{percentage}}% ہے۔",
    ModalBodyDefault:
      "محترم {{fullName}}، آپ نے کامیابی کے ساتھ ٹیسٹ مکمل کر لیا ہے۔",
  },
};

export default ur;
