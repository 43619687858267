import { Typography } from "@mui/material";

import { StyledPaper } from "../../components/StyledPaper";

type Variant = "error" | "info";

interface TooltipTitleProps {
  title: string;
  variant?: Variant;
}

export const TooltipTitle = ({
  title,
  variant,
}: TooltipTitleProps): JSX.Element | null => {
  return (
    <StyledPaper
      sx={{
        py: 2,
        px: 3,
        backgroundColor: variant && `${variant}.main`,
      }}
    >
      <Typography
        variant="regularText"
        color={variant ? "primary.contrastText" : "custom.grey.main"}
        align="center"
      >
        {title}
      </Typography>
    </StyledPaper>
  );
};
