import { SyntheticEvent, useState, useLayoutEffect } from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ResizeCallbackData } from "react-resizable";

import { AdditionalContent } from "@packages/store/models/AdditionalContent/AdditionalContent";
import { ScriptItem } from "@packages/store/models/AdditionalContent/ScriptItem";
import { Block } from "@packages/store/models/Block/Block";
import { Course } from "@packages/store/models/Course/CourseModel";
import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { Unit } from "@packages/store/models/Unit/UnitModel";

import { Banner } from "./styledComponents/Banner";
import { ResizableBox } from "./styledComponents/ResizableBox";
import { StyledAdditionalContentView } from "./styledComponents/StyledAdditionalContentView";
import { StyledTab } from "./styledComponents/StyledTab";
import { StyledTabs } from "./styledComponents/StyledTabs";
import { TextWrapper } from "./styledComponents/TextWrapper";
import defaultBanner from "../../assets/img/banner.png";
import {
  additionalContentWidth,
  maxAdditionalContentWidth,
  maxScrollHeight,
  minAdditionalContentWidth,
  tablet,
} from "../../constants/styles";
import { AdditionalContentResizeHandle } from "../AdditionalContentResizeHandle";
import { PDFView } from "../PDFView";
import { ScriptView } from "../ScriptView";
import { ScrollbarComponent } from "../ScrollbarComponent";
import { StyledPaper } from "../StyledPaper";
import { TabPanel } from "../TabPanel";

// Это для того чтобы и в next и на чистом react работало
const DefaultBannerImg =
  typeof defaultBanner === "string"
    ? defaultBanner
    : (defaultBanner as any)?.src;

interface AdditionalContentViewProps {
  course: Course | undefined;
  unit: Unit | undefined;
  block: Block | undefined;
  exercise: Exercise | undefined;
  script: ScriptItem[] | null;
  scriptHidden: boolean;
  currentScriptItem: string;
  onScriptItemChange: (scriptItem: ScriptItem) => void;

  showWhenEmpty: boolean;
}

export const AdditionalContentView = observer(
  ({
    course,
    unit,
    block,
    exercise,
    script,
    scriptHidden,
    currentScriptItem,
    onScriptItemChange,
    showWhenEmpty,
  }: AdditionalContentViewProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isTouchScreen = useMediaQuery(theme.breakpoints.down(tablet));

    const [currentTab, setCurrentTab] = useState(0);
    const [width, setWidth] = useState(Infinity);

    const { contentImageLongForExercise: courseBanner } = course || {};

    const { contentImageLongForExercise: unitBanner } = unit || {};

    const banner = courseBanner?.url || unitBanner?.url || DefaultBannerImg;

    const { additionalContent: blockAdditionalContent } = block || {};

    const {
      additionalContent: allExerciseAdditionalContent = [],
      isConfirmation,
    } = exercise || {};

    const exerciseAdditionalContent = allExerciseAdditionalContent.find(
      (content) => !content.isVideo
    );

    const showBlockAdditionalContent =
      blockAdditionalContent && !blockAdditionalContent.isVideo;

    const showExerciseAdditionalContent =
      !isConfirmation &&
      exerciseAdditionalContent &&
      !exerciseAdditionalContent.isVideo;

    const showBanner =
      !showBlockAdditionalContent && !showExerciseAdditionalContent;

    const handleTabChange = (_event: SyntheticEvent, tab: number) => {
      setCurrentTab(tab);
    };

    const handleResize = (
      _event: SyntheticEvent,
      { size }: ResizeCallbackData
    ) => {
      setWidth(size.width);
    };

    const renderTextContent = (text: string): JSX.Element | null => {
      if (isTouchScreen) {
        return <TextWrapper dangerouslySetInnerHTML={{ __html: text }} />;
      }

      return (
        <ScrollbarComponent
          containerStyles={{
            [theme.breakpoints.down(tablet)]: {
              ".os-host": {
                maxHeight: maxScrollHeight,
              },
            },
          }}
        >
          <TextWrapper dangerouslySetInnerHTML={{ __html: text }} />
        </ScrollbarComponent>
      );
    };

    const renderImageContent = (contentUrl: string): JSX.Element | null => {
      return (
        <Box
          sx={{
            // 52 это сумма отступов внутри контента
            minWidth: additionalContentWidth - 52,
            img: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <img src={contentUrl} alt="additional-content-img" />
        </Box>
      );
    };

    const renderAdditionalContent = (
      provider: AdditionalContent
    ): JSX.Element | null => {
      const { isText, isImage, isPdf, text, contentUrl } = provider;

      if (isText) {
        return renderTextContent(String(text));
      }

      if (isImage) {
        return renderImageContent(String(contentUrl));
      }

      if (isPdf) {
        return <PDFView file={String(contentUrl)} inWidthChange={width} />;
      }

      return null;
    };

    const renderContent = () => {
      if (!scriptHidden && script) {
        return (
          <ScriptView
            currentScriptItem={currentScriptItem}
            script={script}
            onScriptItemChange={onScriptItemChange}
          />
        );
      }

      if (showBanner) {
        return <Banner src={banner} alt="banner-img" />;
      }

      return (
        <>
          {showBlockAdditionalContent && showExerciseAdditionalContent && (
            <StyledTabs value={currentTab} onChange={handleTabChange}>
              <StyledTab label={t("Exercise:LanguageSamples")} />
              <StyledTab label={t("Exercise:Extras")} />
            </StyledTabs>
          )}

          {(showBlockAdditionalContent || showExerciseAdditionalContent) && (
            <Box sx={{ p: 4 }}>
              {showBlockAdditionalContent && (
                <TabPanel value={currentTab} tabValue={0}>
                  {renderAdditionalContent(blockAdditionalContent)}
                </TabPanel>
              )}

              {showExerciseAdditionalContent && (
                <TabPanel value={currentTab} tabValue={1}>
                  {renderAdditionalContent(exerciseAdditionalContent)}
                </TabPanel>
              )}
            </Box>
          )}
        </>
      );
    };

    useLayoutEffect(() => {
      if (showExerciseAdditionalContent) {
        setCurrentTab(1);
      } else {
        setCurrentTab(0);
      }
    }, [showExerciseAdditionalContent]);

    useLayoutEffect(() => {
      if (isTouchScreen) return;

      setWidth(additionalContentWidth);
    }, [isTouchScreen]);

    if ((!showWhenEmpty || isTouchScreen) && showBanner && scriptHidden) {
      return null;
    }

    if (isTouchScreen) {
      return (
        <StyledPaper
          sx={{
            display: "flex",
            flexDirection: "column",
            order: 0,
          }}
        >
          {renderContent()}
        </StyledPaper>
      );
    }

    return (
      <StyledAdditionalContentView>
        <ResizableBox
          className="box"
          width={width}
          height={Infinity}
          minConstraints={[minAdditionalContentWidth, Infinity]}
          maxConstraints={[maxAdditionalContentWidth, Infinity]}
          onResize={handleResize}
          handle={<AdditionalContentResizeHandle />}
          {...(!isTouchScreen && {
            resizeHandles: ["w"],
          })}
        >
          <StyledPaper
            sx={{
              display: "flex",
              flexDirection: "column",
              width,
              overflow: "hidden",
              [theme.breakpoints.up(tablet)]: {
                pl: "20px",
              },
            }}
          >
            {renderContent()}
          </StyledPaper>
        </ResizableBox>
      </StyledAdditionalContentView>
    );
  }
);
