import { Box, BoxProps, styled } from "@mui/material";

interface TabProps extends BoxProps {
  active: boolean;
}

export const Tab = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<TabProps>(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  gap: "8px",
  transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",

  "&:first-of-type": {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  },

  "&:last-of-type": {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },

  flex: 1,

  ...(active
    ? {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.custom.grey.main,
        border: `1px solid transparent`,
      }
    : {
        cursor: "pointer",
        border: `1px solid ${theme.palette.custom.grey.main}`,

        "&:hover": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.custom.grey.main,
        },
      }),
}));
