import React, { useCallback, useState, useEffect } from "react";

import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import { useCancelableFetch } from "@packages/store/hooks";
import { ExerciseResultUpdateData } from "@packages/store/models/ExerciseResult/ExerciseResult";

import { AnswerWrapper } from "./AnswerWrapper/AnswerWrapper";
import { CheckingHistory } from "./CheckingHistory";
import styles from "./TaskDetails.module.scss";
import { useUser } from "../../hooks";
import { formatTime } from "../../utils/helpers";
import { Icon } from "../Icon";
import { Loading } from "../Loading";
import { Modal } from "../Modal";
import { Notification, NotificationType } from "../Notification";
import { Text } from "../Text";

interface TaskDetailsProps {
  id: string | undefined;
  onClose?: () => void;
}

export const TaskDetails = observer((props: TaskDetailsProps) => {
  const { id, onClose } = props;

  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState<NotificationType>("info");

  const isOpen = id !== undefined;

  const { api, exerciseResult } = useStores();

  const { fetch: fetchDetails, loading: detailsLoading } = useCancelableFetch();

  const { items: results } = exerciseResult;

  const { isTeacher } = useUser();

  const details = results.find((result) => result.id === id);

  const {
    type,
    student,
    taskText,
    unit,
    part,
    exercise,
    courseName,
    checkingHistory,
    hasErrors,
    teacher,
    comment,
    errors,
    corrections,
    passings,
    isUpdating,
    isSubmiting,
    update,
    updateAndSubmit,
  } = details ?? {};

  const handleMessageClear = useCallback(() => {
    setMessage("");
  }, [setMessage]);

  const showMessageWithType = useCallback(
    (message: string, type: NotificationType) => {
      setMessage(message);
      setMessageType(type);
    },
    [setMessage, setMessageType]
  );

  const renderMessage = useCallback(
    () => (
      <Notification
        classNames={styles.message}
        open={!!message}
        onClose={handleMessageClear}
        text={message}
        type={messageType}
      />
    ),
    [message, messageType, handleMessageClear]
  );

  const handleUpdate = useCallback(
    async (params: ExerciseResultUpdateData) => {
      await update?.(params);
      const message = isTeacher
        ? t("CreativeTasks:UpdateSavedTeacher")
        : t("CreativeTasks:UpdateSavedStudent");
      showMessageWithType(message, "info");
    },
    [t, update, showMessageWithType]
  );

  const handleSubmit = useCallback(
    async (params: ExerciseResultUpdateData) => {
      await updateAndSubmit?.(params);
      const message = isTeacher
        ? t("CreativeTasks:UpdateSubmitedTeacher")
        : t("CreativeTasks:UpdateSubmitedStudent");
      showMessageWithType(message, "success");
    },
    [isTeacher, t, updateAndSubmit, showMessageWithType]
  );

  useEffect(() => {
    handleMessageClear();
  }, [id, handleMessageClear]);

  useEffect(() => {
    if (!id || taskText) {
      return;
    }
    fetchDetails((token) =>
      api.getExerciseResultDetails(
        {
          id,
          isTeacher: isTeacher ?? false,
        },
        token
      )
    );
  }, [id, taskText, isTeacher, api, fetchDetails]);

  return (
    <Modal
      className={styles["container"]}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div className={styles["inner"]}>
        <div className={styles["header"]}>
          <Text
            containerClassName={styles["title"]}
            variant="h2-mob"
            color="base-d"
          >
            {t("CreativeTasks:ResultTitle")}
          </Text>
          <Icon
            containerClassName={styles.close}
            icon="close-modal"
            onClick={onClose}
          />
        </div>

        <div className={styles["cards"]}>
          <div className={styles["task"]}>
            <div className={styles["student"]}>
              <Text variant="text-1-semibold" color="base-d">
                {isTeacher ? student : teacher}
              </Text>
              <Text variant="text-3-regular" color="base-dl">
                {t(
                  isTeacher ? "CreativeTasks:Student" : "CreativeTasks:Teacher"
                )}
              </Text>
            </div>
            <Text variant="text-2-link" color="base-d">
              {courseName}
            </Text>
            <div className={styles["unit"]}>
              <Text variant="text-2-regular" color="accsent-1s">
                {t("CreativeTasks:TaskSubtitle", {
                  unit,
                  part,
                  exercise,
                })}
              </Text>
              <Text variant="text-2-regular" color="base-dl">
                {t("CreativeTasks:CurrentVersionTime", {
                  time: formatTime(new Date()),
                })}
              </Text>
            </div>
            <Loading loading={detailsLoading}>
              <Text variant="text-2-regular" color="base-d">
                {t("CreativeTasks:TaskText")}
              </Text>
              <Text variant="text-2-regular" color="base-d">
                <div dangerouslySetInnerHTML={{ __html: taskText ?? "" }} />
              </Text>
              <CheckingHistory
                history={checkingHistory}
                currentDate={new Date()}
              />
            </Loading>
          </div>
          <AnswerWrapper
            type={type}
            comment={comment ?? ""}
            errors={errors ?? []}
            passings={passings ?? []}
            corrections={corrections ?? []}
            isTeacher={isTeacher ?? false}
            hasErrors={hasErrors}
            detailsLoading={detailsLoading}
            onSubmit={handleSubmit}
            onUpdate={handleUpdate}
            renderMessage={renderMessage}
            updateLoading={isUpdating ?? false}
            submitLoading={isSubmiting ?? false}
          />
        </div>
      </div>
    </Modal>
  );
});
