import { useState, ChangeEvent, FormEvent } from "react";

import {
  FormControlLabel,
  Typography,
  Box,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";
import { AddedWord, Definition, WordToAdd } from "@packages/store/services/Api";

import { StyledButton } from "./styledComponents/StyledButton";
import { StyledCheckbox } from "./styledComponents/StyledCheckbox";
import { FlexList } from "../../components/FlexList";
import { Loading } from "../../components/Loading";
import { ensure } from "../../helpers/ensure";
import { useFetch } from "../../hooks/useFetch";

interface VocabularyProps {
  word: string;
  definitions: Definition[];
  transcription: string;
  languageFrom: string;
  languageTo: string;
  onClose: () => void;
}

export const Vocabulary = ({
  word,
  definitions,
  transcription,
  languageFrom,
  languageTo,
  onClose,
}: VocabularyProps): JSX.Element | null => {
  const { t } = useTranslation();

  // const navigate = useNavigate();

  const [selectedTranslations, setSelectedTranslations] = useState<string[]>(
    []
  );
  const [addedWords, setAddedWords] = useState<AddedWord[]>([]);

  const { api } = useStores();

  const {
    fetch: addWords,
    loading: addWordsLoading,
    error: addWordsError,
    success: addWordsSuccess,
  } = useFetch(
    (formData: FormData) => api.addWords(formData),
    (data) => setAddedWords(data)
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    const newSelectedTranslations = checked
      ? [...selectedTranslations, value]
      : selectedTranslations.filter((translation) => translation !== value);

    setSelectedTranslations(newSelectedTranslations);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const wordsToAdd: WordToAdd[] = selectedTranslations.map((translation) => {
      const { partOfSpeech } = ensure(
        definitions.find(({ translations }) =>
          translations.includes(translation)
        )
      );

      return {
        heading: word,
        translation,
        language_from: languageFrom,
        language_to: languageTo,
        transcription,
        part_of_speech: partOfSpeech,
      };
    });

    const formData = new FormData();

    wordsToAdd.forEach((wordToAdd, index) =>
      Object.entries(wordToAdd).map(([key, value]) =>
        formData.append(`entries[${index}][${key}]`, value)
      )
    );

    addWords(formData);
  };

  const error =
    selectedTranslations.length < 1 || selectedTranslations.length > 3;

  if (addWordsError) {
    return (
      <Typography variant="regularText" color="error.main">
        {t("Exercise:AddWordsFailed")}
      </Typography>
    );
  }

  if (addWordsSuccess) {
    const handleClick = () => {
      // navigate("/my-words");
    };

    return (
      <div>
        <Typography variant="regularText" color="success.main">
          {t("Exercise:AddWordsSuccess")}
        </Typography>

        <FlexList
          gap={0}
          items={addedWords}
          renderItem={({ translation, partOfSpeech, addedBefore }) => (
            <Box
              key={translation}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                variant="regularText"
                color="custom.grey.main"
                sx={{ fontStyle: "italic" }}
              >
                {partOfSpeech}
              </Typography>

              <Typography variant="regularText">{translation}</Typography>

              {addedBefore && (
                <Typography variant="regularText" color="info.main">
                  {t("Exercise:AddedBefore")}
                </Typography>
              )}
            </Box>
          )}
          containerStyles={{
            mb: 3,
          }}
        />

        <Box sx={{ display: "flex", gap: 3 }}>
          <StyledButton onClick={handleClick}>
            {t("Exercise:LearnWords")}
          </StyledButton>

          <StyledButton close onClick={onClose}>
            {t("Exercise:Close")}
          </StyledButton>
        </Box>
      </div>
    );
  }

  return (
    <Loading cover loading={addWordsLoading}>
      <FormControl
        required
        error={error}
        component="form"
        onSubmit={handleSubmit}
        sx={{ display: "flex", flexDirection: "column", gap: 3 }}
      >
        <FlexList
          gap={2}
          items={definitions}
          renderItem={({ partOfSpeech, translations }) => (
            <div key={partOfSpeech}>
              <Typography
                variant="regularText"
                color="custom.grey.main"
                sx={{ fontStyle: "italic" }}
              >
                {partOfSpeech}
              </Typography>

              <FlexList
                gap={2}
                items={translations}
                renderItem={(translation) => (
                  <FormControlLabel
                    key={translation}
                    label={
                      <Typography variant="regularText">
                        {translation}
                      </Typography>
                    }
                    control={
                      <StyledCheckbox
                        value={translation}
                        checked={selectedTranslations.includes(translation)}
                        onChange={handleChange}
                      />
                    }
                    sx={{ m: 0 }}
                  />
                )}
              />
            </div>
          )}
        />

        <FormHelperText sx={{ m: 0 }}>
          {t("Exercise:SelectedTranslationsWarning")} *
        </FormHelperText>

        <StyledButton type="submit" disabled={error}>
          {t("Exercise:AddToDictionary")}
        </StyledButton>
      </FormControl>
    </Loading>
  );
};
