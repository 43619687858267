import { useLocation, useParams } from "react-router";

import { TeacherReport } from "@packages/teachers-reports";

import { JetRedirect } from "components/JetRedirect";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";
import { ROUTES } from "router/constants";

export const DetailReportRedirect = (): JSX.Element => {
  const { pathname } = useLocation();
  const { id } = useParams();

  if (pathname.includes(ROUTES.STUDENTS_REPORTS)) {
    return <JetRedirect url={`/reports/students_reports/${id}`} />;
  }

  if (pathname.includes(ROUTES.TEACHERS_REPORTS)) {
    return (
      <WrapperMainStudents>
        <TeacherReport id={id as string} />
      </WrapperMainStudents>
    );
  }

  return <></>;
};
